import React,{useState,useCallback} from 'react'
import PaginacionSinNumeros from './PaginacionSinNumeros'
import obtenerDatos from './../librerias/obtenerDatos'
export default({titulo,urlBusqueda,elegirElemento,idModal}) =>{
    const URLINVENTARIOUSUARIO= urlBusqueda
    const { cargando,data:productos, paginas,cambiarPagina, buscarData, current  } =obtenerDatos(URLINVENTARIOUSUARIO);
    const [buscar,setBuscar] = useState('');

    const buscarDataPalabra = useCallback(
        () => {
            buscarData(buscar);
        },
        [buscar,buscarData],
      );
    return (
        
        <>
        {/* Modal */}
        <div className="modal fade" id={idModal} tabIndex="-1" role="dialog" aria-labelledby={`${idModal}CenterTitle`} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">{titulo}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="form-row" >
                    <div className="col-12" >
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Código, Nombre" onChange={ e => setBuscar (e.target.value)} />
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" onClick={ buscarDataPalabra } >Buscar</button>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        { cargando ?
                            <div className="text-center pt-5">
                                <h4>Cargando...</h4>
                            </div>
                            :
                            <>
                            {
                                productos.length >0 ?
                                <>
                                <table className="table mb-0 table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Acción</th>
                                            <th scope="col">Nombre</th>
                                            <th scope="col">Código</th>
                                            <th scope="col">Existencia</th>
                                            <th scope="col">Precio</th>
                                            <th scope="col">Almacen</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                        productos.map((object, index) => (
                                        <tr key={index}>
                                            <td> <button className="btn btn-primary" 
                                                onClick={()=>{
                                                 elegirElemento(object) 
                                                 document.querySelectorAll(".cerrar-mocal-producto").forEach((element)=>element.click())
                                                }}  
                                                >Seleccionar</button> </td>
                                            <td>{ object.Nombre }</td>
                                            <td>{ object.Codigo }</td>
                                            <td>{ object.total }</td>
                                            <td>{ object.Precio }</td>
                                            <td>{ object.Almacen }</td>
                                        </tr>
                                        ))
                                        }
                                    </tbody>
                                </table>
                                <PaginacionSinNumeros paginas={paginas} cambiarPagina={cambiarPagina} current={current}  ></PaginacionSinNumeros>
                                </>
                                :
                                <div className="text-center pt-5">
                                    <h4>Sin resultados...</h4>
                                </div> 
                            }
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary cerrar-mocal-producto" data-dismiss="modal">Cerrar</button>
            </div>
            </div>
        </div>
        </div>
        </>
    )
}

import React, {useEffect,useState} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm,Controller } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import obtenerCatalogoSinDefecto from '../../../librerias/obtenerCatalogoSinDefecto'
import swal from 'sweetalert'
import Select from 'react-select';
import obtenerCatalogo from './../../../librerias/obtenerCatalogo'
import {    Link  } from "react-router-dom";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

const AgregarEmpleado =() => {
    const [documentos,setDocumentos] = useState({})
    const [usuarios,setUsuarios] = useState({})
    const [fechaIngreso, setFechaIngreso] = useState(new Date());
    const [fechaNacimiento, setFechaNacimiento] = useState(new Date());
    const [sucursales,setSucursales] = useState({})
    const [planes,setPlanes] = useState({})
    const [estados,setEstados] = useState({})
    const [municipios,setMunicipios] = useState({value: "", label: '--Selecciona un valor--'})
    const [cargandos,setCargados] = useState(true)
    const { register, handleSubmit, errors,setValue, control  } = useForm()

    const traerEstados =async () => {
        var estados= await obtenerCatalogoSinDefecto('auth/estados/catalogo')
        if(estados){
            estados.unshift({value: "", label: '--Selecciona un valor--'})
            setEstados(estados);
        }else{
            setEstados({value: "", label: '--Selecciona un valor--'})
        }
        
    }

    const traerUsuarios =async () => {
        const usuarios= await obtenerCatalogo('auth/admin/usuarios-catalogo-sin-empleado')
        setUsuarios(usuarios);
        setCargados(false)
        setValue("catusuario_id",{value: null, label: '--Selecciona un valor--'})
        
    }
    const traerSucursales =async () => {
        const sucursales= await obtenerCatalogoSinDefecto('auth/admin/sucursales-catalogo')
        if(sucursales || sucursales.length===0){
            sucursales.unshift({value: "", label: '--Selecciona un valor--'})
            setSucursales(sucursales);
        }else{
            setSucursales({value: "", label: '--Selecciona un valor--'})
        }
        
    }
    const traerPlanes =async () => {
        const planes= await obtenerCatalogoSinDefecto('auth/admin/plandias-catalogo')
        if(planes || planes.length===0){
            planes.unshift({value: "", label: '--Selecciona un valor--'})
            setPlanes(planes);
        }else{
            setPlanes({value: "", label: '--Selecciona un valor--'})
        }
        
    }
    const traerDocumentos =async () => {
        const documentos= await obtenerCatalogoSinDefecto('auth/documentos/documentos-catalogo')
        setDocumentos(documentos)
    }

    const onSubmit =async (data,e) => { 
        try {
            document.getElementById("enviar").disabled = true;
                let formData = new FormData(document.getElementById("formulario"));
                if(data.catusuario_id.value !== null){
                    formData.append("catusuario_id",data.catusuario_id.value)
                }
                const response =  await API.post('auth/nominas/empleados-agregar', formData);
                if(response.status===201){
                   swal({
                        title: "Registrado",
                        text: "El colaborador se ha registrado correctamente",
                        icon: "success",
                        button: "Aceptar"
                   }); 
                   document.getElementById("enviar").disabled = false;
                     history.push('/colaboradores')
                }else{
                    document.getElementById("enviar").disabled = false;
                    funciones.tratarerrores(response.status,document.getElementById("respuesta"));
                }
          } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"));
            if(document.getElementById("enviar"))
             document.getElementById("enviar").disabled = false;
          }
  
       }

       useEffect(
        ()=>{
            traerDocumentos();
            traerUsuarios();
            traerSucursales();
            traerPlanes();
            traerEstados();
        }
    ,[]);
    const handleChangeUsuario = e => {
        setValue("catusuario_id", e);
        
        // setValue("encargado", e.value);
    }

    const handleChangeEstado = async e =>{
        try {
            const response =   await API.post('auth/municipios/catalogo',funciones.getFormData({"catestado_id":e.target.value}))
            const data = await response.data
            if(data){
                data.unshift({value: "", label: '--Selecciona un valor--'})
                setMunicipios(data);
            }else{
                setMunicipios({value: "", label: '--Selecciona un valor--'})
            }
        } catch (error) {
            console.log(error)
        }
    }
    
    return (
    <>
    <Titulo titulo="Colaborador" tituloBajo="Agregar &gt; colaborador"></Titulo>

    <form onSubmit={handleSubmit(onSubmit)} id="formulario">
    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header no-actions d-flex align-items-center pb-0 pl-3">
                    <h4>Información principal ▾</h4>
                </div>
                <div className="widget-body pl-1 pr-1 pb-0" >
                    <div className="form-row" >
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="Nombre">Nombre*</label>
                            <input type="text" className="form-control input-90" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                            {errors.Nombre &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="ApellidoPaterno">Apellido P.*</label>
                            <input type="text" className="form-control input-90" id="ApellidoPaterno" name="ApellidoPaterno" ref={register({ required: true })}  />
                            {errors.ApellidoPaterno &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="ApellidoMaterno">Apellido M.*</label>
                            <input type="text" className="form-control input-90" id="ApellidoMaterno" name="ApellidoMaterno" ref={register({ required: true })}  />
                            {errors.ApellidoMaterno &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="FechaNacimiento">Fecha Nac.*</label>
                            <DatePicker
                                selected={fechaNacimiento}
                                className="form-control input-90"
                                dateFormat="yyyy-MM-dd"
                                name="FechaNacimiento"
                                onChange={date=>setFechaNacimiento(date)}
                                 />
                        </div>
                    </div>
                    <div className="form-row" >
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="FechaIngreso">FechaIngreso*</label>
                            <DatePicker selected={fechaIngreso}
                                className="form-control input-90"
                                dateFormat="yyyy-MM-dd"
                                name="FechaIngreso"
                                onChange={date=>setFechaIngreso(date)}
                                 />
                        </div>
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="Sexo">Sexo*</label>
                            <select className="form-control input-90" name="Sexo" id="Sexo" ref={register({ required: true })} >
                                <option value="H">Hombre</option>
                                <option value="M">Mujer</option>
                            </select>
                            {/* <input type="text" className="form-control input-90" id="Nombre" name="Nombre" ref={register({ required: true })}  /> */}
                            {errors.Sexo &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="EstadoCivil">Estado C.*</label>
                            <select className="form-control input-90" name="EstadoCivil" id="EstadoCivil" ref={register({ required: true })} >
                                <option value="NA">N/A</option>
                                <option value="S">Soltero</option>
                                <option value="UL">Union libre</option>
                                <option value="C">Casad@</option>
                                <option value="V">Viud@</option>
                                <option value="D">Divorciad@</option>
                            </select>
                            {errors.EstadoCivil &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="Escolaridad">Escolaridad*</label>
                            <select className="form-control input-90" name="Escolaridad" id="Escolaridad" ref={register({ required: true })} >
                                <option value="EB">EDUCACION BASICA</option>
                                <option value="EMS">EDUCACION MEDIA SUPERIOR</option>
                                <option value="ES">EDUCACION SUPERIOR</option>
                            </select>
                            {errors.Escolaridad &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                    </div>
                    <div className="form-row" >
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="LugarNacimiento">Lugar Nac*</label>
                            <input type="text" className="form-control input-90" id="LugarNacimiento" name="LugarNacimiento" ref={register({ required: true })}  />
                            {errors.LugarNacimiento &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="descripcion">&nbsp;</label>
                        </div>
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="descripcion">&nbsp;</label>
                        </div> 
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="descripcion">&nbsp;</label>
                        </div>
                    </div>
                </div>
                <div className="widget-header no-actions d-flex align-items-center pb-0 pl-3">
                    <h4>Domicilio ▾</h4>
                </div>
                <div className="widget-body pl-1 pr-1 pb-0" >
                    <div className="form-row" >
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="catestado_id">Estado*</label>
                            <select className="form-control input-90" 
                            onChange={handleChangeEstado}   name="catestado_id" id="catestado_id" ref={register({ required: true })} >
                                {
                                    estados.length >0 ?
                                    estados.map((object, index) => (
                                        <option value={object.value}  key={index}>{object.label}</option>
                                    ))
                                    :
                                    ""
                                                            
                                }
                            </select>
                            {errors.catestado_id &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>   
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="catmunicipio_id">Municipios*</label>
                            <select className="form-control input-90" name="catmunicipio_id" id="catmunicipio_id" ref={register({ required: true })} >
                                {
                                    municipios.length >0 ?
                                    municipios.map((object, index) => (
                                        <option value={object.value}  key={index}>{object.label}</option>
                                    ))
                                    :
                                    ""               
                                }
                            </select>
                            {errors.catmunicipio_id &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div> 
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="CP">CP*</label>
                            <input type="text" className="form-control input-90" id="CP" name="CP" ref={register({ required: true, maxLength: 10, pattern: /[0-9]/i })}  />
                            {errors.CP &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="Colonia">Colonia*</label>
                            <input type="text" className="form-control input-90" id="Colonia" name="Colonia" ref={register({ required: true })}  />
                            {errors.Colonia &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                    </div>
                    <div className="form-row" >
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="Calle">Calle*</label>
                            <input type="text" className="form-control input-90" id="Calle" name="Calle" ref={register({ required: true })}  />
                            {errors.Calle &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="Numext">Núm. ext*</label>
                            <input type="number" className="form-control input-90" id="Numext" name="Numext" ref={register({ required: true, maxLength: 10, pattern: /[0-9]/i })}  />
                            {errors.Numext &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="Numint">Núm. int*</label>
                            <input type="number" className="form-control input-90" id="Numint" name="Numint" ref={register()}  />
                        </div>
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="descripcion">&nbsp;</label>
                        </div>   
                    </div>
                </div>
                <div className="widget-header no-actions d-flex align-items-center pb-0 pl-3">
                    <h4>Datos de identificación ▾</h4>
                </div>
                <div className="widget-body pl-1 pr-1 pb-0" >
                    <div className="form-row" >
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="Curp">CURP*</label>
                            <input type="text" className="form-control input-90" id="Curp" name="Curp" ref={register({ required: true })}  />
                            {errors.Curp &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="RFC">RFC*</label>
                            <input type="text" className="form-control input-90" id="RFC" name="RFC" ref={register({ required: true })}  />
                            {errors.RFC &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="NSS">NSS*</label>
                            <input type="text" className="form-control input-90" id="NSS" name="NSS" ref={register({ required: true })}  />
                            {errors.NSS &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="INE">INE/IFE*</label>
                            <input type="text" className="form-control input-90" id="INE" name="INE" ref={register({ required: true })}  />
                            {errors.INE &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="widget-header no-actions d-flex align-items-center pb-0 pl-3">
                    <h4>Datos de contacto ▾</h4>
                </div>
                <div className="widget-body pl-1 pr-1 pb-0" >
                    <div className="form-row" >
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="Telefono">Teléfono*</label>
                            <input type="text" className="form-control input-90" id="Telefono" name="Telefono" ref={register({ required: true })}  />
                            {errors.Telefono &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>   
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="Telefono2">Teléfono 2*</label>
                            <input type="text" className="form-control input-90" id="Telefono2" name="Telefono2" ref={register({ required: true })}  />
                            {errors.Telefono2 &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>   
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="correo">Correo*</label>
                            <input type="email" className="form-control input-90" id="Correo" name="Correo" ref={register({ required: true })}  />
                            {errors.Correo &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div> 
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="descripcion">&nbsp;</label>
                        </div>   
                    </div>
                </div>
                <div className="widget-header no-actions d-flex align-items-center pb-0 pl-3">
                    <h4>Contacto de persona ▾</h4>
                </div>
                <div className="widget-body pl-1 pr-1 pb-0" >
                    <div className="form-row" >
                        <div className="form-group col-6 form-tecno">
                            <label htmlFor="ContactoNombre">Nombre contacto*</label>
                            <input type="text" className="form-control input-90" id="ContactoNombre" name="ContactoNombre" ref={register({ required: true })}  />
                            {errors.ContactoNombre &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>   
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="ContactoTelefono">Telefono 1*</label>
                            <input type="text" className="form-control input-90" id="ContactoTelefono" name="ContactoTelefono" ref={register({ required: true })}  />
                            {errors.ContactoTelefono &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>  
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="ContactoTelefono2">Telefono 2*</label>
                            <input type="text" className="form-control input-90" id="ContactoTelefono2" name="ContactoTelefono2" ref={register({ required: true })}  />
                            {errors.ContactoTelefono2 &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>  
                    </div>
                    <div className="form-row" >
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="Parentesco">Parentesco*</label>
                            <select className="form-control input-90" name="Parentesco" id="Parentesco" ref={register({ required: true })} >
                                <option value="H">Herman@</option>
                                <option value="P">Padre</option>
                                <option value="M">Madre</option>
                                <option value="PR">Pareja</option>
                                <option value="O">Otro</option>
                            </select>
                        </div> 
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="descripcion">&nbsp;</label>
                        </div>
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="descripcion">&nbsp;</label>
                        </div>
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="descripcion">&nbsp;</label>
                        </div>
                    </div> 
                </div>
                <div className="widget-header no-actions d-flex align-items-center pb-0 pl-3">
                    <h4>Información interna ▾</h4>
                </div>
                <div className="widget-body pl-1 pr-1 pb-2" >
                    <div className="form-row" >
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="catsucursal_id">Sucursual*</label>
                            <select className="form-control input-90" name="catsucursal_id" id="catsucursal_id" ref={register({ required: true })} >
                                {
                                    sucursales.length >0 ?
                                    sucursales.map((object, index) => (
                                        <option value={object.value}  key={index}>{object.label}</option>
                                    ))
                                    :
                                    ""
                                                            
                                }
                            </select>
                            {errors.catsucursal_id &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>   
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="catplandia_id">Turno*</label>
                            <select className="form-control input-90" name="catplandia_id" id="catplandia_id" ref={register({ required: true })} >
                                {
                                    planes.length >0 ?
                                    planes.map((object, index) => (
                                        <option value={object.value}  key={index}>{object.label}</option>
                                    ))
                                    :
                                    ""
                                                            
                                }
                            </select>
                            {errors.catplandia_id &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>   
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="catusuario_id">Usuario*</label>
                                {cargandos ?
                                "Cargando"
                                :
                                <Controller
                                    
                                name="catusuario_id"
                                control={control}
                                render={({ onChange, onBlur }) => (
                                    <Select
                                    placeholder='--Selecciona un valor--'
                                    onChange={handleChangeUsuario}
                                    options={usuarios}

                                    />
                                )}
                                />
                                }
                        </div> 
                        <div className="form-group col-3 form-tecno">
                            <label htmlFor="descripcion">&nbsp;</label>
                        </div>   
                    </div>
                    <div id="respuesta" ></div>
                    <div className="text-right">
                        <Link to="/colaboradores" className="btn btn-secondary">Atras</Link>
                        <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Cargar archivos</h4>
                </div>
                <div className="widget-body" id="html-file-documentos" >
                    {/* <div className="form-group form-row">
                        <input type="file" className="form-control col-7" />
                        <div className="col-5 pt-1" >
                            <button type="button" className="btn btn-primary">Candelar</button>
                        </div>
                    </div> 
                    <div className="form-group form-row">
                        <input type="file" className="form-control col-7" />
                        <div className="col-5 pt-1" >
                            <button type="button" className="btn btn-primary">Candelar</button>
                        </div>
                    </div>  */
                    }
                    {
                        documentos.length >0 ?
                        documentos.map((object, index) => (
                            <div className="form-group form-row"  key={index}>
                                {object.Requerido===1 ?
                                    <input 
                                        type="file" 
                                        className="form-control col-7" 
                                        name={object.idHtml} 
                                        id={object.idHtml} 
                                        required="true"
                                    />
                                 :
                                    <input 
                                        type="file" 
                                        className="form-control col-7" 
                                        name={object.idHtml} 
                                        id={object.idHtml} 
                                    />
                                 }

                                <div className="col-5 pt-1" >
                                    <button type="button" className="btn btn-secondary" disabled="true">{object.Nombre}</button>
                                    <button 
                                        type="button" 
                                        onClick={ () =>{  
                                            document.getElementById(object.idHtml).value=null;
                                        }
                                        } 
                                        className="btn btn-primary"
                                        >Cancelar
                                    </button>
                                </div>
                            </div>  
                        ))
                        :
                        ""

                    }
                </div>
            </div>
        </div>
    </div>
    </form>
    </>

    )
}

export default AgregarEmpleado;
import React, {useEffect} from 'react';
import {Link} from 'react-router-dom'
import Paginacion from '../../componentes/Paginacion'
import obtenerDatos from '../../librerias/obtenerDatos'
import Busqueda from '../../componentes/Busqueda'
import Titulo from '../../componentes/Titulo'
import API from '../../librerias/api';
import funciones from '../../librerias/funciones';
import obtenerPermisos from '../../librerias/obtenerPermisos';
import icono from '../img/icono-busqueda.png'; 
import swal from 'sweetalert'


const Roles =() => {
    const URLROLES= 'auth/admin/roles'
    const URLROLELIMINAR = '/destroy'
    const { cargando,data:roles, paginas,cambiarPagina, buscarData, current  } =obtenerDatos(URLROLES);
    const permisos  =obtenerPermisos("modulo-roles");



    const eliminar = async (id)=>{

        // console.log(paginas.filter(page=>page.active)[0].page);      
        const respuesta = await  swal({
            title: "¿estas seguro?",
            text: "¿Deseas eliminar esté registro?",
            icon: "warning",
            // buttons: true,
            buttons: ["Cancelar", "Eliminar"],
            dangerMode: true,
          })
          if(respuesta){
              try {
                const response =  await API.delete(URLROLES+"/"+id+URLROLELIMINAR);
                if(response.status===200){
                    swal("Registro elimnado correctamente", {
                        icon: "success",
                    });
                    cambiarPagina(paginas.filter(page=>page.active)[0].label);
                }

            } catch (error) {
                funciones.tratarerrores(error,document.getElementById("respuesta"));
            }

          }

    }

    return (
    <>
    <Titulo titulo="Roles" tituloBajo=""></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Roles registrados</h4>
                    <Busqueda buscarData={buscarData} icono={icono} permiso={permisos} ruta="/AgregarRoles" ></Busqueda>
                </div>
                <div className="pb-5">
                    <div className="table-responsive">
                    { cargando ?
                        <div className="text-center pt-5">
                            <h4>Cargando...</h4>
                        </div>
                        :
                        <>
                        {
                            roles.length >0 ?
                            <>
                            <table className="table mb-0 table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Descripción</th>
                                        <th scope="col">Fecha Creación</th>
                                        <th scope="col">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    roles.map((object, index) => (
                                    <tr key={index}>
                                        <td>{ object.Nombre }</td>
                                        <td>{ object.Descripcion }</td>
                                        <td>{ object.created_at }</td>
                                        <td>
                                            {
                                               permisos.Actualizar ===1  ?
                                               <Link className="" name="id" to={`/editar-rol/${object.id}`}><i className="demo-icon icon-editar color-tecno">&#xe804;</i></Link>
                                                :
                                                ""
                                            }
                                            {
                                               permisos.Visualizar ===1  ?
                                               <Link className="ml-3" name="id" to={`/ver-rol/${object.id}`}><i className="demo-icon icon-ver color-tecno">&#xe814;</i></Link>
                                                :
                                                ""
                                            }
                                            {
                                               permisos.Eliminar ===1  ?
                                               <Link className="ml-3" to="#" onClick={()=>eliminar(object.id)}><i className="demo-icon text-danger icon-eliminar">&#xe805;</i></Link> 
                                                :
                                                ""
                                            }
                                            
                                            
                                        </td>
                                    </tr>
                                    ))
                                    }
                                </tbody>
                            </table>
                            <Paginacion paginas={paginas} cambiarPagina={cambiarPagina} current={current}  ></Paginacion>
                            </>
                            :
                            <div className="text-center pt-5">
                                <h4>Sin resultados...</h4>
                            </div> 
                        }
                        </>
                    }
                    </div>
                </div>
                <div id="respuesta" ></div>
            </div>
        </div>
    </div>
    </>

    // <div className="row">
    //     <div className="col-xl-12">
    //         <div className="widget has-shadow"></div>
    // </div>


    )
}

export default Roles;
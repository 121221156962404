import React, {useEffect, useState} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";
import obtenerCatalogoSinDefecto from '../../../librerias/obtenerCatalogoSinDefecto'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

const EditarCliente =() => {
    const URLCATEGORIALEER = 'auth/ventas/clientes-mostrar/'
    let { id } = useParams();
    const cliente  =obtenerData(URLCATEGORIALEER,id,1)
    const { register, handleSubmit, errors,setValue  } = useForm()
    const [fechaNacimiento, setFechaNacimiento] = useState(new Date());
    // const [estados,setEstados] = useState({})
    // const [municipios,setMunicipios] = useState({})
    const [sucursales,setSucursales] = useState({})

    // const traerMunicpios  =async (cliente) => {
    //     const response =   await API.post('auth/municipios/catalogo',funciones.getFormData({"catestado_id":cliente.catestado_id}))
    //     const data = await response.data
    //     if(data){
    //         data.unshift({value: "", label: '--Selecciona un valor--'})
    //         setMunicipios(data);
    //     }else{
    //         setMunicipios({value: "", label: '--Selecciona un valor--'})
    //     }
    // }
    // const traerEstados =async () => {
    //     var estados= await obtenerCatalogoSinDefecto('auth/estados/catalogo')
    //     if(estados){
    //         estados.unshift({value: "", label: '--Selecciona un valor--'})
    //         setEstados(estados);
    //     }else{
    //         setEstados({value: "", label: '--Selecciona un valor--'})
    //     }
        
    // }
    const traerSucursales =async () => {
        const sucursales= await obtenerCatalogoSinDefecto('auth/admin/sucursales-catalogo')
        if(sucursales || sucursales.length===0){
            sucursales.unshift({value: "", label: '--Selecciona un valor--'})
            setSucursales(sucursales);
        }else{
            setSucursales({value: "", label: '--Selecciona un valor--'})
        }
        
    }
    useEffect(
        ()=>{
        const asignar =(cliente) =>{
            if(cliente.id){
                setValue('Nombre', cliente.Nombre)
                setValue('CP', cliente.CP)
                setValue('Email', cliente.Email)
                setValue('Telefono', cliente.Telefono)
                setValue('Sexo', cliente.Sexo)
                setValue('Edad', cliente.Edad)
                traerSucursales();
                if(cliente.FechaNacimiento==null){
                    setFechaNacimiento(new Date())
                }else{
                    setFechaNacimiento(new Date(cliente.FechaNacimiento.replace(/-/g, '\/')))
                }
            }
            }
        asignar(cliente)
        }
    ,[setValue,cliente]);
    
    const onSubmit =async (data,e) => { 
        try {
            document.getElementById("enviar").disabled = true;
            data.FechaNacimiento =document.getElementById("FechaNacimiento").value;
            const response =  await API.put('auth/ventas/clientes-actualizar/'+cliente.id, data);
            if(response.status===200){
               swal({
                    title: "Actualizado",
                    text: "El cliente se ha actualizado correctamente",
                    icon: "success",
                    button: "Aceptar"
               }); 
               document.getElementById("enviar").disabled = false;
               history.push('/clientes')
            }else{
                document.getElementById("enviar").disabled = false;
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }
          } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"));
            if(document.getElementById("enviar"))
             document.getElementById("enviar").disabled = false;
          }
  
    }
    // const handleChangeEstado = async e =>{
    //     try {
    //         const response =   await API.post('auth/municipios/catalogo',funciones.getFormData({"catestado_id":e.target.value}))
    //         const data = await response.data
    //         if(data){
    //             data.unshift({value: "", label: '--Selecciona un valor--'})
    //             setMunicipios(data);
    //         }else{
    //             setMunicipios({value: "", label: '--Selecciona un valor--'})
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }
    return (
    <>
    <Titulo titulo="Clientes" tituloBajo="Clientes &gt; Editar cliente"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Editar cliente</h4>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label htmlFor="Nombre">Nombre*</label>
                            <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                            {errors.Nombre &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlfor="catsucursal_id">Sucursal*</label>
                            <select className="form-control" name="catsucursal_id" id="catsucursal_id" ref={register({ required: true })} >
                                {
                                    sucursales.length >0 ?
                                    sucursales.map((object, index) => (
                                        cliente.id ?
                                        cliente.catsucursal_id ===object.value ?
                                        <option value={object.value} selected  key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                        

                                    ))
                                    :
                                    ""
                                                            
                                }
                            </select>
                            {errors.catsucursal_id &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>  
                        <div className="form-group">
                            <label htmlfor="Telefono">Teléfono</label>
                            <input type="text" className="form-control" id="Telefono" name="Telefono" ref={register({ required: true })}  />
                            {errors.Telefono &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Sexo">Sexo</label>
                            <select className="form-control" name="Sexo" id="Sexo" ref={register} >
                                <option value="H">Hombre</option>
                                <option value="M">Mujer</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlfor="Email">Correo</label>
                            <input type="email" className="form-control" id="Email" name="Email" ref={register}  />
                        </div>
                        {/* <div className="form-row" >
                            <div className="col-6 form-group">
                                <label htmlfor="catestado_id">Estado*</label>
                                <select className="form-control" 
                                onChange={handleChangeEstado}   name="catestado_id" id="catestado_id" ref={register} >
                                {
                                    estados.length >0 ?
                                    estados.map((object, index) => (
                                        cliente.id ?
                                        cliente.catestado_id ===object.value ?
                                        <option value={object.value} selected  key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                        
                                    ))
                                    :
                                    ""
                                                            
                                }
                                </select>
                            </div>
                            <div className="col-6 form-group">
                                <label htmlfor="catmunicipio_id">Municipios*</label>
                                <select className="form-control" name="catmunicipio_id" id="catmunicipio_id" ref={register} >
                                {
                                    municipios.length >0 ?
                                    municipios.map((object, index) => (
                                        cliente.id ?
                                        cliente.catmunicipio_id ===object.value ?
                                        <option value={object.value} selected  key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                    ))
                                    :
                                    ""               
                                }
                                </select>
                            </div>                           
                        </div> */}
                        {/* <div className="form-group">
                            <label htmlfor="Colonia">Colonia</label>
                            <input type="text" className="form-control" id="Colonia" name="Colonia" ref={register}  />
                        </div>
                        <div className="form-group" >
                            <label htmlfor="Calle">Calle</label>
                            <input type="text" className="form-control" id="Calle" name="Calle" ref={register}  />
                        </div> */}
                        <div className="form-row" >
                            <div className="col-6">
                                <label htmlFor="CP">CP</label>
                                <input type="text" className="form-control" id="CP" name="CP" ref={register({maxLength: 10, pattern: /[0-9]/i})}  />
                            </div>
                            <div className="col-6">
                                <label htmlFor="Sexo">Fecha nacimiento</label>
                                <div style={{width:"100%;"}}>
                                    <DatePicker
                                        selected={fechaNacimiento}
                                        dateFormat="yyyy-MM-dd"
                                        id="FechaNacimiento"
                                        className="form-control"
                                        name="FechaNacimiento"
                                        onChange={date=>setFechaNacimiento(date)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/clientes" className="btn btn-secondary">Atras</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default EditarCliente;
import React from 'react';
import {Link} from 'react-router-dom'
import Paginacion from '../../../componentes/Paginacion'
import obtenerDatos from '../../../librerias/obtenerDatos'
import Busqueda from '../../../componentes/Busqueda'
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import funciones from '../../../librerias/funciones';
import obtenerPermisos from '../../../librerias/obtenerPermisos';
import icono from '../../img/icono-busqueda.png'; 
import swal from 'sweetalert'
import logo from './../../../logoimp.png'; 

const Ventas =() => {
    const URLVENTAS= 'auth/ventas/ventas'
    const URLVENTASELIMINAR = 'auth/ventas/ventas-eliminar/'
    const URLVENTASTICKET = 'auth/ventas/ticket/'
    const { cargando,data:ventas, paginas,cambiarPagina, buscarData, current  } =obtenerDatos(URLVENTAS);
    const permisos  =obtenerPermisos("modulo-ventas");



    const eliminar = async (id)=>{

        // console.log(paginas.filter(page=>page.active)[0].page);      
        const respuesta = await  swal({
            title: "¿estas seguro?",
            text: "¿Deseas eliminar esté registro?",
            icon: "warning",
            // buttons: true,
            buttons: ["Cancelar", "Eliminar"],
            dangerMode: true,
          })
          if(respuesta){
              try {
                const response =  await API.delete(URLVENTASELIMINAR+id);
                if(response.status===200){
                    swal("Registro elimnado correctamente", {
                        icon: "success",
                    });
                    cambiarPagina(paginas.filter(page=>page.active)[0].label);
                }

            } catch (error) {
                funciones.tratarerrores(error,document.getElementById("respuesta"));
            }

          }

    }
    const imprimir= async (id) =>{
        try {
            const response =  await API.get(URLVENTASTICKET+id);
            if(response.status===200){
                var venta=response.data;
                funciones.imprimirVenta(venta,logo);
     
            }else{
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }

        } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"));
        }


    }
    return (
    <>
    <Titulo titulo="Ventas" tituloBajo=""></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Ventas</h4>
                    <Busqueda buscarData={buscarData} icono={icono} permiso={permisos} ruta="/AgregarVenta" ></Busqueda>
                </div>
                <div className="pb-5">
                    <div className="table-responsive">
                    { cargando ?
                        <div className="text-center pt-5">
                            <h4>Cargando...</h4>
                        </div>
                        :
                        <>
                        {
                            ventas.length >0 ?
                            <>
                            <table className="table mb-0 table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Folio</th>
                                        <th scope="col">Cliente</th>
                                        <th scope="col">Empleado</th>
                                        <th scope="col">Subtotal</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    ventas.map((object, index) => (
                                    <tr key={index}>
                                        <td>
                                            { 
                                                object.id
                                            }
                                         </td>
                                        <td>
                                            { 
                                                object.cat_cliente 
                                                ?
                                                object.cat_cliente.Nombre
                                                :
                                                ""
                                         
                                            }
                                         </td>
                                        <td>
                                            {
                                                object.cat_empleado 
                                                ?
                                                object.cat_empleado.Nombre
                                                :
                                                ""
                                            }
                                        </td>
                                        <td>{ "$"+object.Subtotal }</td>
                                        <td>{ "$"+object.Total }</td>
                                        <td>
                                            {
                                               permisos.Actualizar ===1 && permisos.Visualizar ===1  ?
                                               <Link className="" name="id" to={`/consultar-venta/${object.id}`}><i className="demo-icon icon-ver color-tecno">&#xe814;</i></Link>
                                                :
                                                ""
                                            }
                                            {
                                               permisos.Eliminar ===1  ?
                                               <Link className="ml-3" to="#" onClick={()=>eliminar(object.id)}><i className="demo-icon text-danger icon-eliminar">&#xe805;</i></Link> 
                                                :
                                                ""
                                            }
                                            {
                                                object.Pagado===1?
                                               <button className="btn btn-info ml-3"  onClick={()=>imprimir(object.id)} type="button"
                                               >Imprimir</button> 
                                               :
                                               ""
                                            }
                                        </td>
                                    </tr>
                                    ))
                                    }
                                </tbody>
                            </table>
                            <Paginacion paginas={paginas} cambiarPagina={cambiarPagina} current={current}  ></Paginacion>
                            </>
                            :
                            <div className="text-center pt-5">
                                <h4>Sin resultados...</h4>
                            </div> 
                        }
                        </>
                    }
                    </div>
                </div>
                <div id="respuesta" ></div>
            </div>
        </div>
    </div>
    </>


    )
}

export default Ventas;
import React, {useEffect} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";

const EditarProductosFactura =() => {
    const URLPRODUCTOFACTLEER = 'auth/inventarios/productosfacturas-mostrar/'
    let { id } = useParams();
    const productoFact  =obtenerData(URLPRODUCTOFACTLEER,id,1)
    const { register, handleSubmit, errors,setValue  } = useForm()

    useEffect(
        ()=>{
        const asignar =(productoFact) =>{
            setValue('Codigo', productoFact.Codigo)
            setValue('Alias', productoFact.Alias)
            setValue('Descripcion', productoFact.Descripcion)
            }
        asignar(productoFact)
        }
    ,[setValue,productoFact]);
    
    const onSubmit =async (data,e) => { 
        try {
            document.getElementById("enviar").disabled = true;
            const response =  await API.put('auth/inventarios/productosfacturas-actualizar/'+productoFact.id, data);
            if(response.status===200){
               swal({
                    title: "Actualizado",
                    text: "La registro se ha actualizado correctamente",
                    icon: "success",
                    button: "Aceptar"
               }); 
               document.getElementById("enviar").disabled = false;
               history.push('/productosfact')
            }else{
                funciones.tratarerrores(response,document.getElementById("respuesta"));
                document.getElementById("enviar").disabled = false;
            }
          } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"));
            if(document.getElementById("enviar"))
             document.getElementById("enviar").disabled = false;
          }
  
       }
    return (
    <>
    <Titulo titulo="Producto SAT" tituloBajo="Producto SAT &gt; Agregar registro"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Producto SAT</h4>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label htmlFor="Codigo">CLAVE SAT*</label>
                            <input type="text" className="form-control" id="Codigo" name="Codigo" ref={register({ required: true, maxLength: 60 })}  />
                            {errors.Codigo &&
                            <div className="error-form text-red p-small">
                                Este campo no puede estar vacío y su longitud no puede ser mayor a 60!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Alias">Alias*</label>
                            <input type="text" className="form-control" id="Alias" name="Alias" ref={register({ required: true, maxLength: 50 })}  />
                            {errors.Alias &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío y su longitud no puede ser mayor a 50!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Descripcion">Descripción*</label>
                            <textarea className="form-control" id="Descripcion" name="Descripcion" rows="3" ref={register({ required: true })}></textarea>
                            {errors.Descripcion &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/productosfact" className="btn btn-secondary">Atras</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default EditarProductosFactura;
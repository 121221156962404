import React from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";

const AgregarProveedor =() => {
    const { register, handleSubmit, errors  } = useForm()

    const onSubmit =async (data,e) => { 
        try {
            document.getElementById("enviar").disabled = true;
            const response =  await API.post('auth/inventarios/proveedores-agregar', data);
            if(response.status===201){
               swal({
                    title: "Registrado",
                    text: "El proveedor se ha registrado correctamente",
                    icon: "success",
                    button: "Aceptar"
               }); 
               document.getElementById("enviar").disabled = false;
               history.push('/proveedores')
            }else{
                document.getElementById("enviar").disabled = false;
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }
          } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"));
            if(document.getElementById("enviar"))
             document.getElementById("enviar").disabled = false;
          }
  
       }
    return (
    <>
    <Titulo titulo="Proveedores" tituloBajo="Proveedores &gt; Agregar proveedor"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Proveedores</h4>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label htmlFor="Nombre">Nombre*</label>
                            <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                            {errors.Nombre &&
                            <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Telefono">Teléfono*</label>
                            <input type="text" className="form-control" id="Telefono" name="Telefono" ref={register({ required: true})}  />
                            {errors.Telefono &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Email">Correo*</label>
                            <input type="email" className="form-control" id="Email" name="Email" ref={register({ required: true})}  />
                            {errors.Email &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío y debe ser un correo!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Direccion">Dirección*</label>
                            <textarea className="form-control" id="Direccion" name="Direccion" rows="3" ref={register({ required: true })}></textarea>
                            {errors.Direccion &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/proveedores" className="btn btn-secondary">Atras</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default AgregarProveedor;
import React, {useEffect} from 'react';
// import Titulo from '../../../componentes/Titulo'
// import API from '../../../librerias/api';
// import history from '../../../librerias/history';
// import { useForm } from 'react-hook-form'
// import funciones from '../../../librerias/funciones'
// import {    useParams} from "react-router-dom";
// import obtenerData from '../../../librerias/obtenerData'
// import swal from 'sweetalert'
// import {    Link  } from "react-router-dom";
import history from './../librerias/history';

import bienvenido from './bienvenido.png';


const EditarCategoriaProducto =() => {
    // let { id } = useParams();

    useEffect(
        ()=>{
            const params= new URLSearchParams(window.location.search)
            if(params.get('ruta')==="cliente"){
                history.push('/AgregarCliente?clo=1')
            }
        }
    ,[]);
    
    // const onSubmit =async (data,e) => { 
    //     try {
    //         document.getElementById("enviar").disabled = true;
    //         const response =  await API.put('auth/inventarios/categorias-actualizar/'+CategoriaProducto.id, data);
    //         if(response.status===200){
    //            swal({
    //                 title: "Actualizado",
    //                 text: "La categoria se ha actualizado correctamente",
    //                 icon: "success",
    //                 button: "Aceptar"
    //            }); 
    //            document.getElementById("enviar").disabled = false;
    //            history.push('/CategoriaProductos')
    //         }else{
    //             document.getElementById("enviar").disabled = false;
    //             funciones.tratarerrores(response,document.getElementById("respuesta"));
    //         }
    //       } catch (error) {
    //         funciones.tratarerrores(error,document.getElementById("respuesta"));
    //         if(document.getElementById("enviar"))
    //          document.getElementById("enviar").disabled = false;
    //       }
  
    //    }
    return (
    <>

    <span className="" ></span>
    <div className="text-center col-12" style={{paddingBottom: "166px"}}>
        <img src={bienvenido} />
    </div>

   
    </>

    )
}

export default EditarCategoriaProducto;
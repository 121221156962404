import React, {useEffect,useState} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm,Controller } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import swal from 'sweetalert'
import {    Link,useParams  } from "react-router-dom";
import obtenerCatalogo from './../../../librerias/obtenerCatalogo'
import Select from 'react-select';
import obtenerCatalogoSinDefecto from '../../../librerias/obtenerCatalogoSinDefecto'
import obtenerValorcfg from '../../../librerias/obtenerValorcfg'
import obtenerData from '../../../librerias/obtenerData'

const clsproducto = {
    catproducto_id:  {value: null, label: '--Selecciona un valor--'},
    Precio: "",
    Cantidad: 1,
    Total : "",
    Almacen : "",
};

const VerCompra =() => {
    const { register, handleSubmit, errors,setValue, control  } = useForm()
    const URLCOMPRALEER = 'auth/inventarios/compras-mostrar/'
    let { id } = useParams();
    const compra  =obtenerData(URLCOMPRALEER,id,1)
    const [productoselect,setProductooselect] = useState({})
    const [productoselectC,setProductooselectC] = useState(true)
    const [provedores,setProvedores] = useState({})
    const [provedorec,setProvedorec] = useState(true)
    const [provedorev,setProvedorev] = useState(true)
    const [productos,setProductos] = useState([clsproducto])
    const [iva,setiva] = useState(16)
    const [almacenes,setAlmacenes] = useState({})
    const ivaID=1;




    const traerIVA =async () => {
        const iva= await obtenerValorcfg('auth/cfg-ver/'+ivaID)
        setiva(iva.Valor);
        
    }
    const traerAlmacenes =async () => {
        const almacenes= await obtenerCatalogoSinDefecto('auth/inventarios/almacenes-catalogotodos')
        if(almacenes || almacenes.length===0){
            almacenes.unshift({value: "", label: '--Selecciona un valor--'})
            setAlmacenes(almacenes);
        }else{
            setAlmacenes({value: "", label: '--Selecciona un valor--'})
        }
        
    }
    const traerProvedores =async (compra) => {
        const provedores= await obtenerCatalogo('auth/inventarios/proveedores-catalogo')
        setProvedores(provedores);
        setProvedorec(false)
        var valor=compra.catproveedor_id;
        var provedor=provedores.filter(pro => pro.value===valor)[0];
        if(provedor){
            setProvedorev(provedor)
            setValue("catproveedor_id", provedor);
        }else{
            setValue("catproveedor_id",{value: null, label: '--Selecciona un valor--'})
            setProvedorev({value: null, label: '--Selecciona un valor--'})
        }
    }
    const traerProductos =async () => {
        const productoselect= await obtenerCatalogo('auth/inventarios/productos-catalogo')
        setProductooselect(productoselect);
        setProductooselectC(false)
        setValue("catproducto_id",{value: null, label: '--Selecciona un valor--'})
    }
    const validar =(productos) =>{
        var bandera = false;
        var banderaPreciosCantidades = false;
        var banderaProductos = false;
        productos.forEach((produc,index)=>{
            if(produc.Precio<=0 || produc.Cantidad<=0 ) 
                banderaPreciosCantidades = true;
            if(produc.catproducto_id.value == null)
                banderaProductos =true;
        })
        if(banderaPreciosCantidades){
            document.getElementById("respuesta").innerHTML ="Todos los precios y cantidades deben ser mayor a 0"
        }
        if(banderaProductos){
            document.getElementById("respuesta").innerHTML ="Debes seleccionar un producto en todas las partidas"
        }
        bandera = !banderaProductos && !banderaPreciosCantidades ? true : false;
        return bandera;
    }

    useEffect(
        ()=>{
        const asignar =(compra) =>{
            if(compra.id){

                traerProvedores(compra);
                var productosArray = [];
                traerProductos();
                traerAlmacenes();
                traerIVA();
                // const count = animals.push('cows');
                compra.trn_detalles_compras.forEach((detalleC,index)=>{
                    var valor=detalleC.cat_producto.id;
                    // var producto=productoselect.filter(pro => pro.value===valor)[0];
                    var productoD = {
                        catproducto_id:  {value: detalleC.cat_producto.id, label: detalleC.cat_producto.Nombre},
                        Precio: detalleC.Costo,
                        Cantidad: detalleC.Cantidad,
                        Total : detalleC.Subtotal,
                        Almacen : detalleC.catalmacen_id,
                    };
                    productosArray.push(productoD);
                })
                setProductos(productosArray)
            }
            setValue('Nota', compra.Nota)
            }

        asignar(compra)
        }
    ,[compra]);
    const handleChangeProvedor = e => {
        setValue("catproveedor_id", e);
        setProvedorev(e)
    }
    const handleChangeProducto = (e,index) => {
        // console.log(e)
        handleCambiar(e.value,"Producto",index)
    }


    const calcularTotal = (produc) =>{
        return produc.Cantidad*produc.Precio; 
    }
    const handleCambiar = async (valor,concepto,indexx) =>{
        try {
            var  proNuevos = [];
            var RE = /^\d*\.?\d*$/;
            if (!RE.test(valor)) {
                return false;
            }
            switch (concepto) {
                case "Precio":
                    proNuevos= productos.map((producto,index )=>  {

                        if(indexx ===index){
                            producto.Precio = valor
                            producto.Total = calcularTotal(producto)
                        }
                        return producto;
    
                    })
                break;
                case "Cantidad":
                    proNuevos= productos.map((producto,index )=>  {
    
                        if(indexx ===index){
                            producto.Cantidad = valor
                            producto.Total = calcularTotal(producto)
                        }
                        return producto;
    
                    })
                break;
                case "Producto":
                    var response =   await API.get('auth/inventarios/productos-mostrar/'+valor)
                    var productoData = await response.data
                    
                    proNuevos= productos.map((producto,index )=>  {
    
                        if(indexx ===index){
                            producto.Precio = productoData.Costo;
                            producto.catproducto_id = { value:valor, label:productoData.Nombre }
                            console.log(producto)
                            producto.Total = calcularTotal(producto)
                        }
                        return producto;
    
                    })
                break;
                case "Almacen":
                    proNuevos= productos.map((producto,index )=>  {
    
                        if(indexx ===index){
                            producto.Almacen = valor
                        }
                        return producto;
    
                    })
                break;
                default:
                    break;
            }
            setProductos([...proNuevos]);
            
        } catch (error) {
            console.log("error",error)
        }  
    }

    return (
    <>
    <Titulo titulo="Compras" tituloBajo="Compras &gt; Agregar compra"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Realizar compra</h4>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                    <form >
                        <div className="form-group">
                            <label for="catproveedor_id">Proveedor*</label>
                            {provedorec ?
                            "Cargando"
                            :
                            <Controller
                                name="catproveedor_id"
                                control={control}
                                render={({ onChange, onBlur }) => (
                                    <Select
                                    placeholder='--Selecciona un valor--'
                                    onChange={handleChangeProvedor}
                                    options={provedores}
                                    isDisabled={true}
                                    value={provedorev}
                                    />
                                )}
                            />
                            }
                        </div>
                        <span className="d-none" >Cargando precios y montos...</span>
                        {
                            productoselectC ? 
                            "Cargando"
                            :
                            productos.map((object, index) => {
                                const fieldName = `catproducto_id[${index}]`;
                                return (                               
                                <div className="form-group form-row" key={index}>
                                    <div className="col-4" >
                                        <label for={`${fieldName}.catproducto_id`}>Producto*</label>
                                        <Controller
                                        name={`${fieldName}.catproducto_id`}
                                        control={control}
                                        render={({ onChange, onBlur }) => (
                                                <Select
                                                placeholder='--Selecciona un valor--'
                                                onChange={(e)=>{handleChangeProducto(e,index)}}
                                                isDisabled={true}
                                                options={productoselect}
                                                value={productos[index].catproducto_id}
        
                                                />
                                            )}
                                        />
                                         {/* handleCambiar(e,"Precio",index) */}
                                    </div>
                                    <div className="col-2" >
                                        <label for={`${fieldName}.Precio`}>Precio.*</label>
                                        <input  type="text"  className="form-control" id={'Precio'+index} name={`${fieldName}.Precio`} value={productos[index].Precio}  required  disabled="true" />
                                    </div>
                                    <div className="col-2" >
                                        <label for={`${fieldName}.Cantidad`}>Cant.*</label>
                                        <input  type="text" className="form-control" id={'Cantidad'+index}   name={`${fieldName}.Cantidad`} value={productos[index].Cantidad}   required disabled="true" />
                                        {errors.Cantidad &&
                                        <div className="error-form text-red p-small">
                                        Este campo no puede estar vacío y necesita ser un número!
                                        </div>
                                        }
                                    </div>
                                    <div className="col-2" >
                                        <label for={`${fieldName}.Total`}>Total*</label>
                                        <input type="text" className="form-control" id={'Total'+index} name={`${fieldName}.Total`} value={productos[index].Total}  readOnly="true" required />
                                        {errors.Cantidad &&
                                        <div className="error-form text-red p-small">
                                        Este campo no puede estar vacío y necesita ser un número!
                                        </div>
                                        }
                                    </div>
                                    <div className="col-2" >
                                    <label for="catalmacen_id">Almacen*</label>
                                    <select className="form-control" name={`${fieldName}.Total`}  id={'Almacen'+index} required value={productos[index].Almacen} disabled="true" >
                                        {
                                            almacenes.length >0 ?
                                            almacenes.map((object, index) => (
                                                <option value={object.value}  key={index}>{object.label}</option>
                                            ))
                                            :
                                            ""
                                        }
                                    </select>
                                    </div>
                                </div>
                                )
                            })
                        }
                        {/* <div className="col-md-12 pt-5 p-0">
                            <a href="javascript:void(0);" onClick={addProducto} className="btn-block alert alert-outline-primary dashed text-center" >Agregar otro producto</a>
                        </div> */}
                        <div className="form-row" >
                            <div className="form-group col-8">
                                <label for="descripcion">Nota*</label>
                                <textarea className="form-control" id="Nota" name="Nota" rows="3" ref={register({ required: true })} readOnly="true"></textarea>
                                {errors.Nota &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>

                            <div className="col-4" >
                                <table>
                                    <tr>
                                        <td>Subtotal:</td>
                                        <td >
                                            {       
                                            compra.Subtotal
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Iva</td>
                                        <td >
                                            {       
                                            
                                            compra.IVA
                                           
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td >
                                            {
                                            compra.Total
                                            }
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/compras" className="btn btn-secondary">Atras</Link>
                            {/* <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button> */}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default VerCompra;
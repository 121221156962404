import React, {useEffect} from 'react';
import Titulo from '../../../componentes/Titulo'
import { useForm } from 'react-hook-form'
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import {    Link  } from "react-router-dom";

const VerProveedor =() => {
    const URLPROVEEDORESLEER = 'auth/inventarios/proveedores-mostrar/'
    let { id } = useParams();
    const proveedores  =obtenerData(URLPROVEEDORESLEER,id,1)
    const { register, handleSubmit, errors,setValue  } = useForm()

    useEffect(
        ()=>{
        const asignar =(proveedores) =>{
            setValue('Nombre', proveedores.Nombre)
            setValue('Telefono', proveedores.Telefono)
            setValue('Email', proveedores.Email)
            setValue('Direccion', proveedores.Direccion)
            }
        asignar(proveedores)
        }
    ,[setValue,proveedores]);
    

    return (
    <>
    <Titulo titulo="Proveedores" tituloBajo="Proveedores &gt; Editar proveedor"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Proveedores</h4>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                    <form >
                        <div className="form-group">
                            <label htmlFor="Nombre">Nombre*</label>
                            <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                            {errors.Nombre &&
                            <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Telefono">Teléfono*</label>
                            <input type="text" className="form-control" id="Telefono" name="Telefono" ref={register({ required: true})}  />
                            {errors.Telefono &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Email">Correo*</label>
                            <input type="email" className="form-control" id="Email" name="Email" ref={register({ required: true})}  />
                            {errors.Email &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío y debe ser un correo!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Direccion">Dirección*</label>
                            <textarea className="form-control" id="Direccion" name="Direccion" rows="3" ref={register({ required: true })}></textarea>
                            {errors.Direccion &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/proveedores" className="btn btn-secondary">Atras</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default VerProveedor;
import axios from 'axios';
var tokenBarer = "";
if(localStorage.getItem('user')){
  var usertoke = JSON.parse(localStorage.getItem('user'));
  var tokenBarer = "Bearer "+usertoke.access_token;

}



export default axios.create({
  //baseURL: 'http://127.0.0.1:8000/api',
  baseURL: 'https://tecnoland.versionuno.uno/back/public/api',
  headers: {'Content-Type': 'application/json','X-Requested-With':'XMLHttpRequest','Authorization':`${tokenBarer}`}
});
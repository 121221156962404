
import React  , {useEffect,useState}  from "react";
import Login from "./paginas/Login";
import Dashboard from "./paginas/Dashboard";
import DashboardU from "./paginas/Dashboard-u";

import {
  Switch,
  Route
} from "react-router-dom";
import Header from './componentes/Header/Header';
import MenuLateral from './componentes/MenuLateral';
import Footer from './componentes/Footer/Footer';

import Roles from './paginas/Roles/Roles'
import AgregarRoles from './paginas/Roles/AgregarRoles'
import VerRol from './paginas/Roles/VerRol'
import EditarRoles from './paginas/Roles/EditarRoles'


import Permisos from './paginas/Permisos/Permisos'
import AgregarPermiso from './paginas/Permisos/AgregarPermisos'
import EditarPermisos from './paginas/Permisos/EditarPermisos'
import VerPermisos from './paginas/Permisos/VerPermisos'

import Usuarios from './paginas/Usuarios/Usuarios'
import AgregarUsuario from './paginas/Usuarios/AgregarUsuario'
import EditarUsuario from './paginas/Usuarios/EditarUsuario'
import VerUsuario from './paginas/Usuarios/VerUsuario'


import CategoriaProductos from './paginas/inventario/CategoriaProductos/CategoriaProductos'
import AgregarCategoriaProducto from './paginas/inventario/CategoriaProductos/AgregarCategoriaProducto'
import EditarCategoriaProducto from './paginas/inventario/CategoriaProductos/EditarCategoriaProducto'
import VerCategoriaProducto from './paginas/inventario/CategoriaProductos/VerCategoriaProducto'

import ProductosFactura from './paginas/inventario/ProductosFactura/ProductosFactura'
import AgregarProductosFactura from './paginas/inventario/ProductosFactura/AgregarProductosFactura'
import EditarProductosFactura from './paginas/inventario/ProductosFactura/EditarProductosFactura'
import VerProductosFactura from './paginas/inventario/ProductosFactura/VerProductosFactura'

import Proveedores from './paginas/inventario/Proveedores/Proveedores'
import AgregarProveedor from './paginas/inventario/Proveedores/AgregarProveedor'
import EditarProveedor from './paginas/inventario/Proveedores/EditarProveedor'
import VerProveedor from './paginas/inventario/Proveedores/VerProveedor'

import AgregarEmpleado from './paginas/nomina/Empleados/AgregarEmpleado'
import Empleados from './paginas/nomina/Empleados/Empleados'
import EditarEmpleado from './paginas/nomina/Empleados/EditarEmpleado'
import VerEmpleado from './paginas/nomina/Empleados/VerEmpleado'


import Sucursales from './paginas/Admin/Sucursales/Sucursales'
import AgregarSucursal from './paginas/Admin/Sucursales/AgregarSucursal'
import EditarSucursal from './paginas/Admin/Sucursales/EditarSucursal'
import VerSucursal from './paginas/Admin/Sucursales/VerSucursal'


import Almacenes from './paginas/inventario/Almacenes/Almacenes'
import AgregarAlmacenes from './paginas/inventario/Almacenes/AgregarAlmacenes'
import EditarAlmacen from './paginas/inventario/Almacenes/EditarAlmacen'
import VerAlmacen from './paginas/inventario/Almacenes/VerAlmacen'

import Productos from './paginas/inventario/Productos/Productos'
import AgregarProducto from './paginas/inventario/Productos/AgregarProducto'
import EditarProducto from './paginas/inventario/Productos/EditarProducto'
import VerProducto from './paginas/inventario/Productos/VerProducto'

import TiposMovimiento from './paginas/inventario/TiposMovimiento/TiposMovimiento'
import AgregarTipoMovimiento from './paginas/inventario/TiposMovimiento/AgregarTipoMovimiento'
import VerTipoMovimiento from './paginas/inventario/TiposMovimiento/VerTipoMovimiento'
import EditarTipoMovimiento from './paginas/inventario/TiposMovimiento/EditarTipoMovimiento'


import Ajustes from './paginas/inventario/Movimientos/Ajustes'
import AgregarAjuste from './paginas/inventario/Movimientos/AgregarAjuste'
import VerAjuste from './paginas/inventario/Movimientos/VerAjuste'
// TiposMovimeinto
// import AgregarPermiso from './paginas/Permisos/AgregarPermiso'
import Movimientos from './paginas/inventario/Movimientos/Movimientos'
import AgregarMovimiento from './paginas/inventario/Movimientos/AgregarMovimiento'
import VerMovimiento from './paginas/inventario/Movimientos/VerMovimiento'


import UnidadesClave from './paginas/inventario/Unidades/UnidadesClave'
import AgregarUnidadesClave from './paginas/inventario/Unidades/AgregarUnidadesClave'
import EditarUnidadesClave from './paginas/inventario/Unidades/EditarUnidadesClave'
import VerUnidadesClave from './paginas/inventario/Unidades/VerUnidadesClave'

import Conceptos from './paginas/nomina/Conceptos/Conceptos'
import AgregarConceptos from './paginas/nomina/Conceptos/AgregarConceptos'
import EditarConcepto from './paginas/nomina/Conceptos/EditarConcepto'
import VerConcepto from './paginas/nomina/Conceptos/VerConcepto'

import Compras from './paginas/inventario/Compras/Compras'
import VerCompra from './paginas/inventario/Compras/VerCompra'
import AgregarCompra from './paginas/inventario/Compras/AgregarCompra'

import Marcas from './paginas/inventario/Marcas/Marcas'
import AgregarMarca from './paginas/inventario/Marcas/AgregarMarca'
import EditarMarca from './paginas/inventario/Marcas/EditarMarca'
import VerMarca from './paginas/inventario/Marcas/VerMarca'


import Clientes from './paginas/Ventas/Clientes/Clientes'
import AgregarCliente from './paginas/Ventas/Clientes/AgregarCliente'
import EditarCliente from './paginas/Ventas/Clientes/EditarCliente'
import VerCliente from './paginas/Ventas/Clientes/VerCliente'

import Ventas from './paginas/Ventas/Ventas/Ventas'
import AgregarVenta from './paginas/Ventas/Ventas/AgregarVenta'
import ConsultarVenta from './paginas/Ventas/Ventas/ConsultarVenta'

import Servicios from './paginas/Ventas/Servicios/Servicios'
import AgregarServicio from './paginas/Ventas/Servicios/AgregarServicio'
import VerServicio from './paginas/Ventas/Servicios/VerServicio'
import EditarServicio from './paginas/Ventas/Servicios/EditarServicio'

import VerificacionesClientes from './paginas/Ventas/VerificacionesClientes/VerificacionesClientes'


import Inventario from './paginas/inventario/Inventario/Inventario'


import Bienvenido from './paginas/Bienvenido'


// ver-servicio
const App =()=>{
  // localStorage.clear();
  const [usuario,setUsuario] = useState(false);
  useEffect(() => { 
    if(localStorage.getItem('user')){
      const user = JSON.parse(localStorage.getItem('user'));
      var ims = Date.parse(user.expires_at);
      var fecha = new Date(ims);
       var fechaActualIms = Date.now();
       var fechaActual = new Date(fechaActualIms);
       if(fechaActual<=fecha){
        setUsuario(true);
       }else{
        setUsuario(false);
        localStorage.clear();
       }
    }
    
    },[]);

  return (

      usuario===false && localStorage.getItem('user')==null ?
      <Login cambiarUsuario={ ()=>{setUsuario(true);;  }} />
      :
      <>
      <div className="page" >
      <Header /> 
      <div className="page-content d-flex align-items-stretch" >
        <MenuLateral />
        <div className="content-inner">
        <div className="container-fluid">
          <Switch>
              <Route path="/dashboard">
                <Dashboard></Dashboard>
              </Route>
              <Route path="/dashboard-u">
                <DashboardU></DashboardU>
              </Route>
              <Route path="/roles">
                <Roles></Roles>
              </Route>
              <Route path="/AgregarRoles">
                <AgregarRoles></AgregarRoles>
              </Route>
              <Route exact path='/editar-rol/:id?' component={EditarRoles}/>  
              <Route exact path='/ver-rol/:id?' component={VerRol}/>  
              <Route path="/usuarios">
                <Usuarios></Usuarios>
              </Route>
              <Route path="/AgregarUsuario">
                <AgregarUsuario></AgregarUsuario>
              </Route>
              <Route exact path='/editar-usuario/:id?' component={EditarUsuario}/>  
              <Route exact path='/ver-usuario/:id?' component={VerUsuario}/>
              <Route path="/sucursales">
                <Sucursales></Sucursales>
              </Route>
              <Route path="/AgregarSucursal">
                <AgregarSucursal></AgregarSucursal>
              </Route>
              <Route exact path='/editar-sucursal/:id?' component={EditarSucursal}/>  
              <Route exact path='/ver-sucursal/:id?' component={VerSucursal}/>
              {/* nomina */}
              <Route path="/colaboradores">
                <Empleados></Empleados>
              </Route>
              <Route path="/AgregarEmpleado">
                <AgregarEmpleado></AgregarEmpleado>
              </Route>
              <Route exact path='/editar-empleado/:id?' component={EditarEmpleado}/> 
              <Route exact path='/ver-empleado/:id?' component={VerEmpleado}/> 
              <Route path="/permisos">
                <Permisos></Permisos>
              </Route>
              <Route path="/AgregarPermisos">
                <AgregarPermiso></AgregarPermiso>
              </Route>
              <Route exact path='/editar-permiso/:id?' component={EditarPermisos}/>  
              <Route exact path='/ver-permiso/:id?' component={VerPermisos}/>
              <Route path="/almacenes">
                <Almacenes></Almacenes>
              </Route>
              <Route path="/AgregarAlmacenes">
                <AgregarAlmacenes></AgregarAlmacenes>
              </Route>
              <Route exact path='/editar-almacen/:id?' component={EditarAlmacen}/>  
              <Route exact path='/ver-almacen/:id?' component={VerAlmacen}/>  
              <Route path="/categoriaproductos">
                <CategoriaProductos></CategoriaProductos>
              </Route>
              <Route path="/AgregarCategoriaProducto">
                <AgregarCategoriaProducto></AgregarCategoriaProducto>
              </Route>
              <Route exact path='/editar-categoriaproducto/:id?' component={EditarCategoriaProducto}/>   
              <Route exact path='/ver-categoriaproductos/:id?' component={VerCategoriaProducto}/>   
              <Route path="/productosfact">
                <ProductosFactura></ProductosFactura>
              </Route>
              <Route path="/AgregarProductosFactura">
                <AgregarProductosFactura></AgregarProductosFactura>
              </Route>
              <Route exact path='/editar-productosfact/:id?' component={EditarProductosFactura}/> 
              <Route exact path='/ver-productosfact/:id?' component={VerProductosFactura}/> 
              <Route path="/proveedores">
                <Proveedores></Proveedores>
              </Route>
              <Route path="/AgregarProveedor">
                <AgregarProveedor></AgregarProveedor>
              </Route>
              <Route exact path='/editar-proveedor/:id?' component={EditarProveedor}/> 
              <Route exact path='/ver-proveedor/:id?' component={VerProveedor}/> 
              <Route path="/productos">
                <Productos></Productos>
              </Route>
              <Route path="/AgregarProducto">
                <AgregarProducto></AgregarProducto>
              </Route>
              <Route exact path='/editar-producto/:id?' component={EditarProducto}/> 
              <Route exact path='/ver-producto/:id?' component={VerProducto}/> 
              <Route path="/tiposmovimiento">
                <TiposMovimiento></TiposMovimiento>
              </Route>
              <Route path="/AgregarTipoMovimiento">
                <AgregarTipoMovimiento></AgregarTipoMovimiento>
              </Route>
              <Route exact path='/editar-tipomovimiento/:id?' component={EditarTipoMovimiento}/> 
              <Route exact path='/ver-tipomovimiento/:id?' component={VerTipoMovimiento}/> 
              <Route path="/ajustes">
                <Ajustes></Ajustes>
              </Route>
              <Route path="/AgregarAjuste">
                <AgregarAjuste></AgregarAjuste>
              </Route>
              <Route exact path='/ver-ajuste/:id?' component={VerAjuste}/> 
              <Route path="/movimientoalmacenes">
                <Movimientos></Movimientos>
              </Route>
              <Route path="/AgregarMovimiento">
                <AgregarMovimiento></AgregarMovimiento>
              </Route>
              <Route exact path='/ver-movimiento/:id?' component={VerMovimiento}/> 
              <Route path="/unidadescvl">
                <UnidadesClave></UnidadesClave>
              </Route>
              <Route path="/AgregarUnidadesClave">
                <AgregarUnidadesClave></AgregarUnidadesClave>
              </Route>
              <Route exact path='/editar-unidadcvl/:id?' component={EditarUnidadesClave}/> 
              <Route exact path='/ver-unidadcvl/:id?' component={VerUnidadesClave}/>
              <Route path="/compras">
                <Compras></Compras>
              </Route>
              <Route path="/AgregarCompra">
                <AgregarCompra></AgregarCompra>
              </Route>
              <Route exact path='/ver-compra/:id?' component={VerCompra}/>
              <Route path="/marcas">
                <Marcas></Marcas>
              </Route>
              <Route path="/AgregarMarca">
                <AgregarMarca></AgregarMarca>
              </Route>
              <Route exact path='/editar-marca/:id?' component={EditarMarca}/>
              <Route exact path='/ver-marca/:id?' component={VerMarca}/>
              <Route path="/clientes">
                <Clientes></Clientes>
              </Route>
              <Route path="/AgregarCliente">
                <AgregarCliente></AgregarCliente>
              </Route>
              <Route exact path='/ver-cliente/:id?' component={VerCliente}/>
              <Route exact path='/editar-cliente/:id?' component={EditarCliente}/>
              <Route path="/ventas">
                <Ventas></Ventas>
              </Route>
              <Route path="/AgregarVenta">
                <AgregarVenta></AgregarVenta>
              </Route>
              <Route exact path='/consultar-venta/:id?' component={ConsultarVenta}/>
              <Route path="/conceptos">
                <Conceptos></Conceptos>
              </Route>
              <Route path="/AgregarConceptos">
                <AgregarConceptos></AgregarConceptos>
              </Route>
              <Route exact path='/editar-concepto/:id?' component={EditarConcepto}/>
              <Route exact path='/ver-concepto/:id?' component={VerConcepto}/>
              <Route path="/servicios">
                <Servicios></Servicios>
              </Route>
              <Route path="/VerificacionesClientes">
                <VerificacionesClientes></VerificacionesClientes>
              </Route>
              <Route path="/agregarservicio">
                <AgregarServicio></AgregarServicio>
              </Route>
              <Route exact path='/editar-servicio/:id?' component={EditarServicio}/>
              <Route exact path='/ver-servicio/:id?' component={VerServicio}/>
              <Route path="/inventario">
                <Inventario></Inventario>
              </Route>
              <Route  exact path='/' component={Bienvenido} />
          </Switch>
        </div>
          <Footer />
        </div>
      </div>
      </div>
      </>
  );
}

export default App;

import React, {useEffect} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";

const EditarUnidadesClave =() => {
    const URLUNIDADESCVLLEER = 'auth/inventarios/unidades-mostrar/'
    let { id } = useParams();
    const unidadescvl  =obtenerData(URLUNIDADESCVLLEER,id,1)
    const { register, handleSubmit, errors,setValue  } = useForm()

    useEffect(
        ()=>{
        const asignar =(unidadescvl) =>{
            setValue('Clave', unidadescvl.Clave)
            setValue('Nombre', unidadescvl.Nombre)
            setValue('Simbolo', unidadescvl.Simbolo)
            }
        asignar(unidadescvl)
        }
    ,[setValue,unidadescvl]);
    
    const onSubmit =async (data,e) => { 
        try {
            document.getElementById("enviar").disabled = true;
            const response =  await API.put('auth/inventarios/unidades-actualizar/'+unidadescvl.id, data);
            if(response.status===200){
               swal({
                    title: "Actualizado",
                    text: "La registro se ha actualizado correctamente",
                    icon: "success",
                    button: "Aceptar"
               }); 
               document.getElementById("enviar").disabled = false;
               history.push('/unidadescvl')
            }else{
                funciones.tratarerrores(response,document.getElementById("respuesta"));
                document.getElementById("enviar").disabled = false;
            }
          } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"));
            if(document.getElementById("enviar"))
             document.getElementById("enviar").disabled = false;
          }
  
       }
    return (
    <>
    <Titulo titulo="Unidades Clave" tituloBajo="Unidades Clave &gt; Editar registro"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Unidades Clave</h4>
                </div>
                <div className="widget-body" >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label htmlFor="Clave">Clave*</label>
                            <input type="text" className="form-control" id="Clave" name="Clave" ref={register({ required: true })}  />
                            {errors.Codigo &&
                            <div className="error-form text-red p-small">
                                Este campo no puede estar vacío !
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Nombre">Nombre*</label>
                            <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                            {errors.Alias &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío !
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Simbolo">Simbolo*</label>
                            <input type="text" className="form-control" id="Simbolo" name="Simbolo" ref={register({ required: true })}  />
                            {errors.Alias &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/unidadescvl" className="btn btn-secondary">Atras</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default EditarUnidadesClave;
import React, {useEffect,useState} from 'react';
import Titulo from '../../componentes/Titulo'
import { useForm,Controller } from 'react-hook-form'
import obtenerData from '../../librerias/obtenerData'
import {    Link,useParams  } from "react-router-dom";
import obtenerCatalogo from './../../librerias/obtenerCatalogo'
import Select from 'react-select';


import perfil from './perfil.png';
const VerUsuario =() => {
    const [roles,setRoles] = useState({})
    const [cargando,setCargado] = useState(true)
    const [rol, setRol] = useState(null);
    const URLUSUARIOLEER = 'auth/admin/usuarios-mostrar/'
    let { id } = useParams();
    const usuario  =obtenerData(URLUSUARIOLEER,id,1)
    const { register, handleSubmit, errors,setValue,control  } = useForm()
    const traerRoles =async () => {
        const roles= await obtenerCatalogo('auth/admin/roles-catalogo')
        setRoles(roles);
        setCargado(false)
        var valor=parseInt(id);
        var rol=roles.filter(role => role.value===valor)[0];
        if(rol){
            setRol(rol)
            setValue("catrol_id", rol);
        }else{
            setValue("catrol_id",{value: null, label: '--Selecciona un valor--'})
        }
        
    }

    useEffect(
        ()=>{
        const asignar =(usuario) =>{
            setValue('name', usuario.name)
            setValue('email', usuario.email)
            document.getElementById("img-carga").src=usuario.img;
            traerRoles();
            }
        asignar(usuario)
        }
    ,[setValue,usuario]);
    

    const handleChangeRol = e => {
        setValue("catrol_id", e);
        setRol(e)
    }
    const errorImg = e =>{
        e.target.src=perfil;
    }
    return (
    <>
    <Titulo titulo="Usuarios" tituloBajo="Usuarios &gt; editar usuario"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Usuarios</h4>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                    <form>
                        <div className="form-group">
                            {/* <label htmlFor="name">Nombre*</label> */}
                            <div className="col-4 mx-auto text-center" >
                                <img src=""  onError={errorImg} id="img-carga" alt="Avatar" style={{"borderRadius":"50%","width":"171px","height":"171px"}} /> 
                                {/* <input type="file" className="form-control" onChange={onChangeImg} id="imgD" name="imgD" ref={register()} accept="image/png,image/jpeg"  /> */}

                            </div>
                            {/* <input type="text" className="form-control" id="name" name="name" ref={register({ required: true })}  /> */}
                        </div>
                        <div className="form-group">
                            <label htmlFor="name">Nombre*</label>
                            <input type="text" className="form-control" id="name" name="name" ref={register({ required: true })}  />
                            {errors.name &&
                            <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Email">Correo*</label>
                            <input type="email" className="form-control" id="email" name="email" ref={register( { required: true} )}  />
                            {errors.email &&
                            <div className="error-form text-red p-small">
                                Este campo no puede estar vacío y debe ser un correo!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="rol">Rol*</label>
                            {cargando ?
                            "Cargando"
                            :
                            <Controller
                                name="catrol_id"
                                control={control}
                                render={({ onChange, onBlur }) => (
                                    <Select
                                    placeholder='--Selecciona un valor--'
                                      onChange={handleChangeRol}
                                        options={roles}
                                        isDisabled={false}
                                        value={rol}
                                        isDisabled={true}
                                    />
                                )}
                            />
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/usuarios" className="btn btn-secondary">Atras</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default VerUsuario;
import React, {useEffect,useState} from 'react';
import Titulo from '../../../componentes/Titulo'
import ModalTablaCliente from '../../../componentes/ModalTablaCliente'
import ModalTablaProducto from '../../../componentes/ModalTablaProducto'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";
import obtenerCatalogoSinDefecto from '../../../librerias/obtenerCatalogoSinDefecto'
import obtenerValorcfg from '../../../librerias/obtenerValorcfg'
import logo from './../../../logoimp.png'; 
import ModalTablaProductoSinInventario from '../../../componentes/ModalTablaProductoSinInventario'

const AgregarVenta =() => {
    const clsproducto = {
        catproducto_id:  {id: null, name: null},
        Precio: "",
        Cantidad: 1,
        Total : "",
        Almacen : "",
        cantidadMax : 0,
    };
    const { register, handleSubmit, errors  } = useForm()
    const [cliente,setCliente] = useState({id:null,nombre:null});
    const [empleados,setEmpleados] = useState({})
    const [productos,setProductos] = useState([clsproducto])
    const [productoActual,setproductoActual] = useState(0)
    const [venta,setVenta] = useState({id:null,monto:0})
    const [conceptosp, setConceptosp] = useState([]);
    const [montopago, setMontoPago] = useState(0);
    const [montopagodec, setMontoPagodec] = useState(0);
    const [visiblemontopago, setVisiblemontopago] = useState(true);
    const ivaID=1;
    const [iva,setiva] = useState(16)
    const URLVENTASTICKET = 'auth/ventas/ticket/'

    const validarCampoDecPago = (valor) =>{
        valor = valor
        var RE = /^\d*(\.\d{1})?\d{0,1}$/;
        if(RE.test(valor)  ){
            setMontoPagodec(valor)
        }
    } 
    const validarCampoEnteroPag = (valor) =>{
        valor = valor
        var RE = /^\d*(\.\d{1})?\d{0,1}$/;
        if(RE.test(valor) ){
            setMontoPago(valor)
        }
    } 

    // conceptosp
    const limpiarConcepto = () => {
        var concep =document.getElementById("concepto")
        var marcav =null
        var preciov =document.getElementById("concepto-precio")
        var id =document.getElementById("concepto-id")
        concep.readOnly = false;
        // marcav.disabled = false;
        preciov.readOnly = false;
        id.readOnly = false;
        concep.value = "";
        // marcav.value = "";
        preciov.value = "";
        id.value = 0;
    }
    const addConceptos = () => {
        // validar decimal 
        var RE = /^\d*(\.\d{1})?\d{0,1}$/;

        var pError=document.getElementById("error-concepto");
        pError.innerHTML = "";

        var concep =document.getElementById("concepto").value
        var marcav =null
        var preciov =document.getElementById("concepto-precio").value
        var idV =document.getElementById("concepto-id").value
        if(concep===""){
            pError.innerHTML="El concepto no puede estar vacío";
            return ;
        }else if(!RE.test(preciov) || preciov === ""){
            pError.innerHTML="El precio debe ser un número con max. dos decimales";
            return ;  
        }

        const clsConceptosp= {
            id: idV,
            concepto: concep,
            marca:  marcav,
            Precio: Number(preciov),
        };
        setConceptosp(conceptosp => [...conceptosp, clsConceptosp]);
    };

    const removeConceptos = indexx => () => {
        setConceptosp(conceptosp => [...conceptosp.filter((concepto, index) =>     (   indexx !==index     ))]);
    };
    const escogerConcepto = object =>{
        var concep =document.getElementById("concepto")
        // var marcav =null
        var preciov =document.getElementById("concepto-precio")
        var id =document.getElementById("concepto-id")
        concep.readOnly = true;
        // marcav.disabled = false;
        preciov.readOnly = true;
        id.readOnly = true;
        concep.value = object.Nombre;
        // marcav.value = object.catmarca_id;
        preciov.value = object.Precio;
        id.value = object.id;
    }
    // conceptos end 


    const traerIVA =async () => {
        const iva= await obtenerValorcfg('auth/cfg-ver/'+ivaID)
        setiva(iva.Valor);
        
    }
    const traerEmpleados =async () => {
        const empleados= await obtenerCatalogoSinDefecto('auth/nominas/empleados-catalogo-sucursal')
        if(empleados || empleados.length===0){
            empleados.unshift({value: "", label: '--Selecciona un valor--'})
            setEmpleados(empleados);
        }else{
            setEmpleados({value: "", label: '--Selecciona un valor--'})
        }
    }

    const pagar =async (data,e) => { 
        try{
            
            let formData = new FormData(document.getElementById("formPagar"));
            formData.append("idVenta",venta.id)
            const response =  await API.post('auth/ventas/crear-pago', formData);
            if(response.status===201){
               swal({
                    title: "Registrado",
                    text: "El pago se llevo correctamente",
                    icon: "success",
                    button: "Aceptar"
               }); 
               imprimir(venta.id)
                history.push('/ventas')
            }else{
                funciones.tratarerrores(response,document.getElementById("respuesta"));
                
            }
        } catch (error) {
        funciones.tratarerrores(error,document.getElementById("respuesta"));
        if(document.getElementById("enviar"))
            document.getElementById("enviar").disabled = false;


        }
    }
    const onSubmit =async (data,e) => { 
        try {
        document.getElementById("enviar").disabled = true;
        document.getElementById("respuesta").innerHTML =""
        var bandera =cliente.id==null ?  false : true;
        var total=Number(productos.reduce((sum, li) => sum + li.Total, 0))+  Number(conceptosp.reduce((sum, li) => sum + li.Precio, 0))
        if(total<=0){
            document.getElementById("respuesta").innerHTML ="El monto no puede ser menor o igual a 0."
            document.getElementById("enviar").disabled = false;
        }else if(!bandera){
            document.getElementById("respuesta").innerHTML ="Debes seleccionar un cliente."
            document.getElementById("enviar").disabled = false;
        }else {
            data.catcliente_id = cliente.id
            data.productos =productos.filter(element => element.catproducto_id.value>0 );
            data.conceptos = conceptosp;
            const response =   await API.post('auth/ventas/ventas-agregar', data);
            if(response.status===201){
                swal({
                    title: "Registrado",
                    text: response.data.Mensaje[0],
                    icon: "success",
                    button: "Aceptar"
                }); 
                document.getElementById("enviar").disabled = false;
                setVenta({id:response.data.Mensaje[1], monto:response.data.Mensaje[2] })
            
            }else{
                document.getElementById("enviar").disabled = false;
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }
        }

        } catch (error) {
        funciones.tratarerrores(error,document.getElementById("respuesta"));
        if(document.getElementById("enviar"))
            document.getElementById("enviar").disabled = false;
        }
  
    }
    useEffect(
        ()=>{
            traerEmpleados();
            traerIVA();
        }
    ,[]);

    const addProducto = () => {
        const clsproductoD = {
            catproducto_id:  {value: null, name: ''},
            Precio: "",
            Cantidad: 1,
            Total : "",
            Almacen : "",
            cantidadMax : 0,
        };
        setProductos(productos => [...productos, clsproductoD]);
    };
    const removeProducto = indexx => () => {
        setProductos(productos => [...productos.filter((producto, index) =>     (   indexx !==index     ))]);
      };
    const calcularTotal = (produc) =>{
        return produc.Cantidad*produc.Precio;
    }
    const handleCambiar = async (valor,concepto,indexx) =>{
        try {
            var  proNuevos = [];
            var RE = /^\d*\.?\d*$/;

            if (!RE.test(valor) && concepto !=="seleccion" ) {
                return false;
            }
            // vemos si hay un producto igual en el arreglo
            document.getElementById("error-agregar").innerHTML ="";


            switch (concepto) {
                case "Precio":
                    proNuevos= productos.map((producto,index )=>  {

                        if(indexx ===index ){
                            producto.Precio = valor
                            producto.Total = calcularTotal(producto)
                        }
                        return producto;
    
                    })
                break;
                case "Cantidad":
                    proNuevos= productos.map((producto,index )=>  {

                        if(indexx ===index && Number(valor)<=Number(producto.cantidadMax) &&valor>0 ){
                            producto.Cantidad = valor
                            producto.Total = calcularTotal(producto)
                        }
                        
                        return producto;
    
                    })
                break;
                case "seleccion":
                    var found = productos.find(producto =>{
                        return producto.catproducto_id.value===valor.id && producto.Almacen===valor.idAlmacen 
        
                    } );
                    if(found){
                        document.getElementById("error-agregar").innerHTML ="Producto ya en la lista";
                        return false;
                    }
                    proNuevos= productos.map((producto,index )=>  {
    
                        if(indexx ===index){
                            producto.catproducto_id =  { value:valor.id, name:valor.Codigo+'|'+valor.Nombre }
                            producto.Precio = valor.Precio
                            producto.Cantidad = 1
                            producto.Almacen = valor.idAlmacen
                            producto.cantidadMax = valor.total
                            producto.Total = calcularTotal(producto)
                        }
                        return producto;
    
                    })
                break;
                default:
                    break;
            }
            setProductos([...proNuevos]);
        } catch (error) {
            console.log("error",error)
        }  
    }
    const onchangleSeleccionarProducto =(data)=>{
        handleCambiar(data,"seleccion",productoActual)
    }
    const imprimir= async (id) =>{
        try {
            const response =  await API.get(URLVENTASTICKET+id);
            if(response.status===200){
                var venta=response.data;
                funciones.imprimirVenta(venta,logo);
            }else{
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }

        } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"));
        }


    }
    const changeEfectivo = (e) =>{
        if(e.target.value==="E"){
            setVisiblemontopago(true)
            setMontoPago(0)
        }else{
            setVisiblemontopago(false)
            setMontoPagodec(0)
        }


    }
    return (
    <>
    <Titulo titulo="Venta" tituloBajo="Ventas &gt; Agregar venta"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="row" >
                    <div className="widget-header bordered no-actions d-flex align-items-center col-11">
                    {
                        venta.id===null?
                        <h4>Agregar venta</h4>
                        :
                        <h4>Información de pago</h4>
                    }
                    </div>
                    <div className=" bordered no-actions d-flex align-items-center text-right">
                    {
                        venta.id?
                        <h4>#{venta.id}</h4>
                        :
                        "..."
                    }
                    </div>
                </div>
                <div className="widget-body" >
                    {
                    venta.id===null?
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-row" >
                            <div className="col-6" >
                                <label htmlFor="catsucursal_id">Cliente*</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" readOnly={true} value={cliente.nombre} />
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" data-toggle="modal" data-target="#exampleModalCenter" type="button">Buscar</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6" >
                                <div className="form-group">
                                    <label htmlFor="catsucursal_id">Colaborador*</label>
                                    <select className="form-control" name="catempleado_id" id="catempleado_id" ref={register} required >
                                    {
                                        empleados.length >0 ?
                                        empleados.map((object, index) => (
                                            <option value={object.value}  key={index}>{object.label}</option>
                                        ))
                                        :
                                        ""
                                    }
                                    </select>
                                </div>  
                            </div>
                        </div>
                        <div id="error-agregar" ></div>
                        {
                            productos.map((object, index) => {
                                const fieldName = `catproducto_id[${index}]`;
                                return (
                                    <div className="form-group form-row" key={index}>
                                        <div className="col-4" >
                                            <label htmlFor={`${fieldName}.catproducto_id`}>Producto*</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" readOnly={true} value={productos[index].catproducto_id.name} />
                                                <div className="input-group-append">
                                                    <button className="btn btn-outline-secondary" data-toggle="modal" data-target="#ModalProductos" type="button" onClick={()=>{setproductoActual(index)}} >Buscar</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2" >
                                            <label htmlFor={`${fieldName}.Precio`}>Precio.*</label>
                                            <input  type="text"  className="form-control" id={'Precio'+index} name={`${fieldName}.Precio`} value={productos[index].Precio} onChange={ (e)=>{handleCambiar(e.target.value,"Precio",index)}} required readOnly />
                                        </div>
                                        <div className="col-2" >
                                            <label htmlFor={`${fieldName}.Cantidad`}>Cant.*(max:{ Math.round(productos[index].cantidadMax)})</label>
                                            <input  type="text" className="form-control" id={'Cantidad'+index}   name={`${fieldName}.Cantidad`} value={productos[index].Cantidad} onChange={ (e)=>{handleCambiar(e.target.value,"Cantidad",index)}}  required />
                                        </div>
                                        <div className="col-2" >
                                            <label htmlFor={`${fieldName}.Total`}>Total*</label>
                                            <input type="text" className="form-control" id={'Total'+index} name={`${fieldName}.Total`} value={productos[index].Total}  readOnly={true} required />
                                        </div>
                                        {
                                             productos.length > 1?
                                            <button type="button" aria-label="Close"  onClick={removeProducto(index)}  style={{background: "red",borderRadius: "65px",width: "23px",fontWeight :"600 !important",height:"10%",marginTop:"15px" }} className="ml-2 mb-1 close">
                                                <span style={{color: "black"}}  >×</span>
                                            </button>
                                            :
                                            ""
                                         }
                                    </div>
                                )
                            })
                        }
                        <div className="col-md-12 pt-5 p-0">
                            <a href="javascript:void(0);" onClick={addProducto} className="btn-block alert alert-outline-primary dashed text-center" >Agregar otro producto</a>
                        </div>
                        <div className="form-row" >
                        <div className="form-group col-8">
                        </div>
                            <div className="form-group col-8">
                                {/* <h3 className="col-12">
                                    Conceptos
                                </h3>
                                <div className="col-4" >
                                    <ul className="list-group">
                                    {
                                            conceptosp.length > 0 ?
                                            conceptosp.map((object, index) => {
                                                const fieldName = `conceptos[${index}]`;
                                                return (
                                                    <li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
                                                        {object.concepto} (${object.Precio} )
                                                        <span className="badge badge-primary badge-pill bg-danger" role="button" onClick={removeConceptos(index)}  >x</span>
                                                    </li>
                                                )
                                            })
                                            :
                                            <p>Sin conceptos</p>

                                    }
                                    </ul>
                                </div>
                                <div className="col-8" >
                                    <div className="input-group">
                                            <input type="text" className="form-control" id="concepto" name="concepto"   placeholder="Concepto/Producto" />
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" data-toggle="modal" data-target="#ModalProductoSinInventario" type="button" >...</button>
                                            </div>
                                    </div>
                                    <input type="hidden" className="form-control pt-3" id="concepto-id" name="concepto-id"   placeholder="Concepto/Producto" />
                                    <div className="form-group" >
                                        <input type="text" className="form-control mt-2" placeholder="Precio" id="concepto-precio" name="concepto-precio" />
                                    </div>
                                    <div className="form-group" >
                                        <p id="error-concepto" >  </p>
                                    </div>
                                    <button type="button" className="btn btn-primary" onClick={addConceptos} >Agregar</button>
                                    <button type="button" className="btn btn-primary ml-3" onClick={limpiarConcepto} >Limpiar</button>
                                </div> */}
                            </div>
                            <div className="col-4" >
                                <table>
                                    <tr>
                                        <td>Subtotal:</td>
                                        <td >
                                            {       
                                               Number(productos.reduce((sum, li) => sum + li.Total, 0))+  Number(conceptosp.reduce((sum, li) => sum + li.Precio, 0))
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Iva</td>
                                        <td >
                                            {       
                                            
                                               (Number(productos.reduce((sum, li) => sum + li.Total, 0)+  Number(conceptosp.reduce((sum, li) => sum + li.Precio, 0)))*iva/100)
                                               
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td >
                                            {
                                              Number(Number(productos.reduce((sum, li) => sum + li.Total, 0))+  Number(conceptosp.reduce((sum, li) => sum + li.Precio, 0))+(Number(productos.reduce((sum, li) => sum + li.Total, 0)+  Number(conceptosp.reduce((sum, li) => sum + li.Precio, 0)))*iva/100))
                                            }
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="text-right">
                            <Link to="/ventas" className="btn btn-secondary">Regresar a las ventas</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                        </div>
                    </form>
                    :
                    <form id="formPagar" onSubmit={handleSubmit(pagar)} >                 
                        <div className="form-row">
                            <div className="col-4" >
                            </div>
                            <div className="col-4">
                                <label>Forma de pago</label>
                                <select className="form-control " name="Pagocon" id="Pagocon" onChange={(e)=>{changeEfectivo(e)}}  >
                                        <option value="E">Efectivo</option>
                                        <option value="TD">Tarjeta de débito</option>
                                        <option value="TC">Tarjeta de crédito</option>
                                        <option value="NA">NA</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-4" >
                            </div>
                            <div className="col-4">
                                <label>Monto a pagar</label>
                                <label className="form-control"  >{venta.monto}</label>          
                            </div>
                        </div>
                        {
                        visiblemontopago?
                        <>
                        <div className="form-row">
                            <div className="col-4" >
                            </div>
                            <div className="col-4">
                                <label>Monto recibido</label>
                                <div className="row pl-3" >
                                <input type="text" className="form-control col-4" onChange={(e)=>{validarCampoEnteroPag(e.target.value)}} value={montopago}    />
                                <label style={{color: "black",fontSize:"24px" }} className="pl-1 pr-1">.</label>
                                <input type="text" className="form-control col-2" onChange={(e)=>{validarCampoDecPago(e.target.value)}} value={montopagodec}   />
                                </div>         
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-4" >
                            </div>
                            <div className="col-4">
                                <label>Monto a devolver</label>
                                <label className="form-control" >{(Number(Number(montopago)+"."+montopagodec)-venta.monto).toFixed(2) <=0 ? 0 :(Number(Number(montopago)+"."+montopagodec)-venta.monto).toFixed(2)}</label>          
                            </div>
                        </div>
                        </>
                        :
                        ""
                        }
                        <div className="form-row">
                            <div className="col-4" >
                            </div>
                            <div className="col-4">
                                <button type="Submit" className="btn btn-primary mr-3" >Pagar</button>   
                                
                                {/* <button className="btn btn-info ml-3"  onClick={()=>imprimir(venta.id)} type="button" >Imprimir</button>  */}
                                <Link to="/ventas" className="btn btn-secondary">Regresar a las ventas</Link>
                            </div>
                        </div>
                    </form>      
                    }
                    <div id="respuesta" ></div>
                </div>
            </div>
        </div>
    </div>
    <>
    <ModalTablaCliente titulo="Clientes" urlBusqueda="auth/ventas/clientes-sucursal" elegirElemento={setCliente} idModal="exampleModalCenter" />
    <ModalTablaProducto titulo="Productos" urlBusqueda="auth/inventario/inventario-usuario" elegirElemento={onchangleSeleccionarProducto} idModal="ModalProductos" />
    {/* <ModalTablaProductoSinInventario titulo="Productos/Conceptos sin inventario" urlBusqueda="auth/inventario/inventario-usuario-sin-inventario" elegirElemento={escogerConcepto} idModal="ModalProductoSinInventario" /> */}
    </>
    </>

    )
}

export default AgregarVenta;
import React from 'react';
import {Link} from 'react-router-dom'
import Paginacion from '../../../componentes/Paginacion'
import obtenerDatos from '../../../librerias/obtenerDatos'
import Busqueda from '../../../componentes/Busqueda'
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import funciones from '../../../librerias/funciones';
import obtenerPermisos from '../../../librerias/obtenerPermisos';
import icono from '../../img/icono-busqueda.png'; 
import swal from 'sweetalert'
import logo from './../../../logoimp.png'; 

const Servicios =() => {
    const URLSERVICIO= 'auth/servicios/servicios'
    const URLSERVICIOELIMINAR = 'auth/servicios/servicios-eliminar/'
    const { cargando,data:Servicios, paginas,cambiarPagina, buscarData, current  } =obtenerDatos(URLSERVICIO);
    const permisos  =obtenerPermisos("modulo-servicios");
    const URLSERVICIOTICKET = "auth/servicios/ticket/"
    const URLSERVICIOTICKETECNICO = "auth/servicios/servicios-ticket/"
    const URLSERVICIOFIRMARECIBIDO = "auth/servicios/firma-recibido/"

    const eliminar = async (id)=>{
        // console.log(paginas.filter(page=>page.active)[0].page);      
        const respuesta = await  swal({
            title: "¿estas seguro?",
            text: "¿Deseas eliminar esté registro?",
            icon: "warning",
            // buttons: true,
            buttons: ["Cancelar", "Eliminar"],
            dangerMode: true,
          })
          if(respuesta){
              try {
                const response =  await API.delete(URLSERVICIOELIMINAR+id);
                if(response.status===200){
                    swal("Registro elimnado correctamente", {
                        icon: "success",
                    });
                    cambiarPagina(paginas.filter(page=>page.active)[0].label);
                }

            } catch (error) {
                funciones.tratarerrores(error,document.getElementById("respuesta"));
            }

          }

    }
    const imprimir= async (folio) =>{
        try {
            const response =  await API.get(URLSERVICIOTICKET+folio);
            // console.log(response)
            if(response.status===200){
                var servicio=response.data;
                funciones.imprimirServicio(servicio,logo);
            }else{
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }
        } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"),error);
        }
    }
    const imprimirTec= async (folio) =>{
        try {
            const response =  await API.get(URLSERVICIOTICKETECNICO+folio);
            // console.log(response)
            if(response.status===200){
                var servicio=response.data;
                servicio.Password =  servicio.Password  ? servicio.Password : "";
                var contenidoHtml ="<div style='width:100%;  text-align: center;font-family: monospace;' >";
                contenidoHtml +=   "<p>"+servicio.Folio +"|"+servicio.created_at +"|"+servicio.Modelo +"|"+servicio.cat_marca.Nombre
                                     +"|"+servicio.cat_cliente.Nombre+"|"+servicio.Telefono+"|"+servicio.cat_falla.Nombre+"|"+servicio.cat_empleado.Nombre+" "
                                     +servicio.cat_empleado.ApellidoMaterno + " "+servicio.cat_empleado.ApellidoPaterno+"|"+servicio.Password+" <p/>";
                
                if(servicio.Patron){
                    contenidoHtml +=    "<img  src='"+servicio.urlDocs+servicio.id+"/"+servicio.Patron+"' style='width:245px;' ></img><br>";
                }
                contenidoHtml +=    servicio.qr;
                contenidoHtml += "</div>";
                var ticket = document.createElement("div");
                ticket.innerHTML =contenidoHtml;
                var ventimp = window.open(' ', 'tecnico');
                ventimp.document.write( ticket.innerHTML );
                ventimp.document.querySelector("body").style.margin = "0px";
                ventimp.document.close();
                ventimp.print( );
                ventimp.close( );
                
            }else{
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }
            

        } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"),error);
        }
    }
    const TicketRecibido= async (folio) =>{
        try {
            var firmaDiv = document.getElementById("qr-firma-correo");
            firmaDiv.innerHTML ="<h2>Cargando....</h2>";
            const response =  await API.get(URLSERVICIOFIRMARECIBIDO+folio);
            // console.log(response)
            if(response.status===200){
                firmaDiv.innerHTML=response.data.qr;
                // document.getElementById("ancla-ser").href = response.data.link;
            }else{
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }


        } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"),error);
        }
    }
    return (
    <>
    <Titulo titulo="Servicios" tituloBajo=""></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Servicios</h4>
                    <Busqueda buscarData={buscarData} icono={icono} permiso={permisos} ruta="/agregarservicio" ></Busqueda>
                </div>
                <div className="pb-5">
                    <div className="table-responsive">
                    { cargando ?
                        <div className="text-center pt-5">
                            <h4>Cargando...</h4>
                        </div>
                        :
                        <>
                        {
                            Servicios.length >0 ?
                            <>
                            <table className="table mb-0 table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Folio</th>
                                        <th scope="col">IMEI</th>
                                        <th scope="col">Sucursal</th>
                                        <th scope="col">Modelo</th>
                                        <th scope="col">Cliente</th>
                                        <th scope="col">Creado</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">Estatus</th>
                                        <th scope="col">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    Servicios.map((object, index) => {
                                    var estatus = "";
                                   
                                    switch (object.Estatus) {
                                        case "1":
                                                estatus ="Recepción"
                                            break;
                                        case "2":
                                                estatus ="Análisis"
                                            break;
                                        case "3A":
                                                estatus ="Reparación"
                                            break;
                                        case "3B":
                                                estatus ="Esperando refacciones"
                                            break;
                                        case "3C":
                                                estatus ="Estado crítico"
                                            break;
                                        case "4A":
                                                if(object.Reparado===1){
                                                    estatus ="Listo entrega - Reparado"
                                                }else
                                                    estatus ="Listo entrega - Sin reparar"
                                            break;
                                        case "5A":
                                            estatus ="Se entregó"
                                            break;
                                        case "6A":
                                                if(object.Reparado===1){
                                                    estatus ="Rezagado - Reparado"
                                                }else
                                                    estatus ="Rezagado - Sin reparar"
                                            break;
                                        default:
                                            break;
                                    }
                                    return <tr key={index}>
                                        <td>
                                            { 
                                                object.FOLIO
                                            }
                                         </td>
                                        <td>
                                            { 
                                                object.IMEI 
                                            }
                                         </td>
                                         <td>
                                            { 
                                                object.cat_sucursal ?
                                                object.cat_sucursal.Nombre
                                                :
                                                ""
                                            }
                                         </td>
                                         <td>
                                            { 
                                                object.cat_marca ?
                                                object.cat_marca.Nombre+"-"+object.Modelo
                                                :
                                                ""
                                            }
                                         </td>
                                         <td>
                                            { 
                                                object.cat_cliente ?
                                                object.cat_cliente.Nombre
                                                :
                                                ""
                                            }
                                         </td>
                                        <td>
                                            {
                                                object.created_at 
                                            }
                                        </td>
                                        <td>{ "$"+object.Total }</td>
                                        <td>{ estatus }</td>
                                        <td>
                                            {
                                               permisos.Visualizar ===1  ?
                                               <Link className="" name="id" to={`/ver-servicio/${object.id}`}><i className="demo-icon icon-ver color-tecno">&#xe814;</i></Link>
                                                :
                                                ""
                                            }
                                            {
                                               permisos.Actualizar ===1 ?
                                               <Link className="ml-3" name="id" to={`/editar-servicio/${object.id}`}><i className="demo-icon icon-editar color-tecno">&#xe804;</i></Link>
                                                :
                                                ""
                                            }
                                            
                                            {
                                               permisos.Eliminar ===1  ?
                                               <Link className="ml-3" to="#" onClick={()=>eliminar(object.id)}><i className="demo-icon text-danger icon-eliminar">&#xe805;</i></Link> 
                                                :
                                                ""
                                            }
                                            {
                                                <>
                                                <button className="btn btn-info ml-3"  onClick={()=>imprimir(object.FOLIO)} type="button"
                                                >Imprimir</button> 
                                                <button className="btn btn-info ml-3"  onClick={()=>imprimirTec(object.FOLIO)} type="button"
                                                >Técnico</button> 
                                                {
                                                    object.Estatus ==="5A" && !object.FirmaRecibido  ?
                                                    <button className="btn btn-success ml-3"  data-toggle="modal" data-target="#modal-qr-seguimiento" onClick={()=>TicketRecibido(object.FOLIO)} type="button"
                                                    >Firma entregado</button> 
                                                    :
                                                    ""
                                                }
                                                </>
                                            }
                                        </td>
                                    </tr>
                                    })
                                    }
                                </tbody>
                            </table>
                            <Paginacion paginas={paginas} cambiarPagina={cambiarPagina} current={current}  ></Paginacion>
                            </>
                            :
                            <div className="text-center pt-5">
                                <h4>Sin resultados...</h4>
                            </div> 
                        }
                        </>
                    }
                    </div>
                </div>
                <div id="respuesta" ></div>
            </div>
        </div>
    </div>
    <div className="modal fade" id="modal-qr-seguimiento" tabIndex="-1" role="dialog" aria-labelledby={`modal-fotoqr`} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">Datos de QR</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                  
                    <div className="row" >
                        <div className="col-sm-12 col-12" >
                            <h3>Firma recibido</h3>
                            <div className="text-center" id="qr-firma-correo" style={{width:"100%"}}  >
                            </div>
                        </div>
                        <div className="col-sm-12 col-12" >
                            {/* <a target="_blank" href="#"  id="ancla-ser">Click aqui</a> */}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" id="cerrar-mocal-cliente" data-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
    </>


    )
}

export default Servicios;
import React, {useEffect,useState} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";
import obtenerCatalogoSinDefecto from '../../../librerias/obtenerCatalogoSinDefecto'

const AgregarAlmacenes =() => {
    const [sucursales,setSucursales] = useState({})
    const { register, handleSubmit, errors  } = useForm()

    const traerSucursales =async () => {
        const sucursales= await obtenerCatalogoSinDefecto('auth/admin/sucursales-catalogo')
        if(sucursales || sucursales.length===0){
            sucursales.unshift({value: "", label: '--Selecciona un valor--'})
            setSucursales(sucursales);
        }else{
            setSucursales({value: "", label: '--Selecciona un valor--'})
        }
        
    }
    const onSubmit =async (data,e) => { 
        try {
            document.getElementById("enviar").disabled = true;
            const response =  await API.post('auth/inventarios/almacenes-agregar', data);
            if(response.status===201){
               swal({
                    title: "Registrado",
                    text: "El inventario se ha registrado correctamente",
                    icon: "success",
                    button: "Aceptar"
               }); 
               document.getElementById("enviar").disabled = false;
               history.push('/almacenes')
            }else{
                document.getElementById("enviar").disabled = false;
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }
          } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"));
            if(document.getElementById("enviar"))
             document.getElementById("enviar").disabled = false;
          }
  
       }
       useEffect(
        ()=>{
            traerSucursales();
        }
    ,[]);
    return (
    <>
    <Titulo titulo="Almacen" tituloBajo="Almacen &gt; Agregar Almacen"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Almacen</h4>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label htmlFor="Nombre">Nombre*</label>
                            <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                            {errors.Nombre &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Direccion">Direción*</label>
                            <input type="text" className="form-control" id="Direccion" name="Direccion" ref={register({ required: true })}  />
                            {errors.Direccion &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Direccion">Sucursal*</label>
                            <select className="form-control" name="catsucursal_id" id="catsucursal_id" ref={register({ required: true })} >
                                {
                                    sucursales.length >0 ?
                                    sucursales.map((object, index) => (
                                        <option value={object.value}  key={index}>{object.label}</option>
                                    ))
                                    :
                                    ""
                                                            
                                }
                            </select>
                            {errors.catsucursal_id &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Descripcion">Descripción*</label>
                            <textarea className="form-control" id="Descripcion" name="Descripcion" rows="3" ref={register({ required: true })}></textarea>
                            {errors.Descripcion &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/almacenes" className="btn btn-secondary">Atras</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default AgregarAlmacenes;
import React, {useEffect} from 'react';
import Titulo from '../../../componentes/Titulo'
import { useForm } from 'react-hook-form'
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import {    Link  } from "react-router-dom";

const VerCategoriaProducto =() => {
    const URLCATEGORIALEER = 'auth/inventarios/categorias-mostrar/'
    let { id } = useParams();
    const CategoriaProducto  =obtenerData(URLCATEGORIALEER,id,1)
    const { register,  errors,setValue  } = useForm()

    useEffect(
        ()=>{
        const asignar =(CategoriaProducto) =>{
            setValue('Nombre', CategoriaProducto.Nombre)
            setValue('Descripcion', CategoriaProducto.Descripcion)
            setValue('Comision', CategoriaProducto.Comision)
            setValue('Codigo', CategoriaProducto.Codigo)
            }
        asignar(CategoriaProducto)
        }
    ,[setValue,CategoriaProducto]);
    

    return (
    <>
    <Titulo titulo="Categoria productos" tituloBajo="Categoria productos &gt; ver categoria"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Categoria producto</h4>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                    <form >
                        <div className="form-group">
                            <label htmlFor="Nombre">Nombre categoria*</label>
                            <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                            {errors.Nombre &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Codigo">Código*</label>
                            <input type="text" className="form-control" id="Codigo" name="Codigo" ref={register({ required: true })}  />
                            {errors.Codigo &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Comision">Comision % (1 al 100)*</label>
                            <input type="number" className="form-control" id="Comision" name="Comision" ref={register({max: 100, min: 1})}  />
                            {errors.Comision &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío y debe estar entre 1 y 100!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Descripcion">Descripción*</label>
                            <textarea className="form-control" id="Descripcion" name="Descripcion" rows="3" ref={register({ required: true })}></textarea>
                            {errors.Descripcion &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/CategoriaProductos" className="btn btn-secondary">Atras</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default VerCategoriaProducto;